import { createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { errorMessageHandler } from "../../_helpers/methods";
import { setUiError, setUiMessage } from "./ui";

export const initialState = {
  loading: false,
  hasErrors: false,
  nakas: [],
  count: 0,
  nakaUsers: {},
};

// A slice
const nakaSlice = createSlice({
  name: "nakas",
  initialState,
  reducers: {
    getNakas: (state, action) => {
      state.nakas = action.payload;
    },
    getAllNakas: (state, action) => {
      state.nakas = action.payload.results;
      state.count = action.payload.count;
    },
    createNaka: (state, action) => {
      state.nakas = [action.payload.data, ...state.nakas];
    },
    deleteNaka: (state, action) => {
      const tempNakas = state.nakas;

      const index = tempNakas.findIndex((wd) => wd.id === action.payload);

      tempNakas.splice(index, 1);

      state.nakas = tempNakas;
    },
    editNaka: (state, action) => {
      const temp = state.nakas.filter((wd) => wd.id !== action.payload.id);
      state.nakas = [...temp, action.payload];
    },
    getNakaUsers: (state, action) => {
      state.nakaUsers = action.payload.data.data;
    },
    createNakaUser: (state, action) => {
      state.nakaUsers.users.push(action.payload.data.users[0]);
    },
    editNakaUser: (state, action) => {
      const editedUser = { ...state.nakaUsers };

      const editedIndex = editedUser.users.findIndex(
        (user) => user.id === action.payload.id
      );
      if (editedIndex !== -1) {
        editedUser.users[editedIndex] = action.payload;
      }
      state.nakaUsers = editedUser;
    },
    activeNakaUser: (state, action) => {
      const tempUser = { ...state.nakaUsers };
      const editedIndex = tempUser.users.findIndex(
        (user) => user.id === action.payload.profile_id
      );
      if (editedIndex !== -1) {
        if (action.payload.status === "active") {
          tempUser.users[editedIndex].is_active = true;
        } else {
          tempUser.users[editedIndex].is_active = false;
        }
      }
      state.nakaUsers = tempUser;
    },
    deleteNakaUser: (state, action) => {
      const tempUsers = { ...state.nakaUsers };
      const index = tempUsers.users.findIndex(
        (user) => user.id === action.payload
      );
      tempUsers.users.splice(index, 1);
      state.nakaUsers = tempUsers;
    },
  },
});

// Actions generated from the slice
const {
  getNakas,
  getAllNakas,
  // getNakaUsers,
  createNaka,
  deleteNaka,
  editNaka,
  activeNakaUser,
  editNakaUser,
  deleteNakaUser,
} = nakaSlice.actions;

// export user selector to get the slice in any component
export const nakaSelector = (state) => state.nakas;

// export The reducer
const nakaReducer = nakaSlice.reducer;

export default nakaReducer;

// Actions
export const fetchNakas =
  (page = 1, id) =>
  async (dispatch) => {
    console.log("khola id", id);
    try {
      const response = await fetchWrapper.get(
        `/khola/${id}/?offset=${(page - 1) * 10}&limit=10`
      );
      dispatch(getNakas(response?.data?.nakas));
    } catch (error) {}
  };
export const fetchAllNakas =
  (page = 1) =>
  async (dispatch) => {
    try {
      const response = await fetchWrapper.get(
        `/naka/?offset=${(page - 1) * 10}&limit=10`
      );
      dispatch(getAllNakas(response?.data));
    } catch (error) {}
  };

export const postNakas = (data, hideNakaForm) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post("/naka/", data);
    dispatch(createNaka(response));
    dispatch(setUiMessage(response?.message));
    hideNakaForm();
  } catch (error) {
    dispatch(setUiError(errorMessageHandler(error)));
  }
};
export const patchNaka = (body, id, closeForm) => async (dispatch) => {
  try {
    const response = await fetchWrapper.patch(`/naka/${id}/`, body);
    console.log(response);
    dispatch(editNaka(response?.data));
    dispatch(setUiMessage(response?.message));
    closeForm();
  } catch (error) {
    dispatch(setUiError(errorMessageHandler(error)));
  }
};
// export const fetchNakaUsers = (id) => async (dispatch) => {
//   try {
//     const nakaUsers = await fetchWrapper.get(`/khola/${id}/`);
//     dispatch(getNakaUsers(nakaUsers));
//   } catch (error) {}
// };
export const removeNaka = (id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.delete(`/naka/${id}/`);
    dispatch(deleteNaka(id));
    dispatch(setUiMessage(response.message));
  } catch (error) {
    dispatch(setUiError(errorMessageHandler(error)));
  }
};

// export const postNakaUser = (data, hideUserForm, id) => async (dispatch) => {
//   try {
//     let body = {
//       users: [
//         {
//           username: data.email,
//           email: data.email,
//           password: data.password,
//           role: data.role,
//           phone: data.phone_en,
//           profile: {
//             post: data.post_en,
//             fullname_en: data.fullname_en,
//             fullname_np: data.fullname_np,
//             address: data.address,
//             phone_en: data.phone_en,
//             phone_np: data.phone_np,
//             post_en: data.post_en,
//             post_np: data.post_np,
//           },
//         },
//       ],
//     };
//     const response = await fetchWrapper.post(`/naka/${id}/create_user/`, body);
//     dispatch(createNakaUser(response));
//     dispatch(setUiMessage(response.message));
//     hideUserForm();
//   } catch (error) {
//     if (error.error?.non_field_error) {
//       dispatch(setUiError(error.error.non_field_error));
//     } else {
//       if (error?.error && Object.keys(error.error).length) {
//         Object.keys(error.error).map((errorKey) => {
//           console.log(error.error[errorKey][0], "error.error[errorKey][0]");
//           return toast.error(error.error[errorKey][0]);
//         });
//       }

//       // dispatch(setUiMessage(error.message));
//     }
//   }
// };

export const patchNakaUser =
  (data, id, setShowAdd, showAdd) => async (dispatch) => {
    try {
      if (data.password === "") {
        delete data.password;
      }
      const response = await fetchWrapper.patch(`/naka-user/${id}/`, data);
      // console.log(response, data);

      dispatch(editNakaUser({ id, ...data }));
      setShowAdd(false);
      dispatch(setUiMessage(response.message));
    } catch (error) {
      console.log(error, "error");
      dispatch(setUiError(error.message));
    }
  };

export const activateKawaNakaUser = (body, closeForm) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post(`/update-kawa-status/`, body);
    dispatch(activeNakaUser(body));
    dispatch(setUiMessage(response.message));
    closeForm();
  } catch (error) {}
};

export const removeNakaUser = (id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.delete(`/naka-user/${id}/`);

    dispatch(deleteNakaUser(id));
    dispatch(setUiMessage(response.message));
  } catch (error) {}
};
