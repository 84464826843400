/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { logoutUser } from "../_redux/slices/users";
import { languageSelector } from "../_redux/slices/languages";
import { nepaliNumberConverter } from "../_helpers/methods";
import { useState, Fragment } from "react";
import ChangePasswordModal from "./ChangePasswordModal";
import BackButton from "../components/BackButton";
import { BiUser } from "react-icons/bi";
import { CgPassword } from "react-icons/cg";
import { FiLogOut } from "react-icons/fi";

const Header = ({ logoutUser }) => {
  const user = useSelector((state) => state.users.user);

  const { language } = useSelector(languageSelector);
  const activeFiscalYear = useSelector(
    (state) => state.fiscal.activeFiscalYear
  );
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const handleShowChangePasswordModal = () => {
    setShowChangePasswordModal(true);
  };
  const handleCloseChangePasswordModal = () => {
    setShowChangePasswordModal(false);
  };

  return (
    <div className="header">
      <div className="header-inner">
        <div className="header-left d-flex align-items-center">
          <BackButton />
          <h4 className="text-white ml-4">
            {activeFiscalYear && (
              <span>
                {language === "nepali" ? (
                  <Fragment>
                    आ.व. : {nepaliNumberConverter(activeFiscalYear?.title)}
                  </Fragment>
                ) : (
                  <Fragment>आ.व. : {activeFiscalYear?.title}</Fragment>
                )}
              </span>
            )}{" "}
          </h4>
        </div>

        <div className="header-right">
          <img
            src="/assets/images/avatar.png"
            className="header-avatar"
            alt=""
          />

          {user && (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                .role {
                  font-size: 12px;
                }
              `}
            >
              <p>{user?.profile?.name}</p>
              <p className="role">
                {/* {user?.role === 'admin' ? user?.role : user?.designation} */}
                {user?.role}
              </p>
            </div>
          )}

          <Dropdown>
            <Dropdown.Toggle
              variant="primary"
              id="dropdown-basic"
              css={css`
                &.dropdown-toggle {
                  color: white !important;
                }
              `}
            ></Dropdown.Toggle>

            <Dropdown.Menu
              css={css`
                transform: translate(-155px, 40px) !important;
              `}
            >
              <Link
                css={css`
                  display: block;
                  width: 100%;
                  padding: 0.25rem 1.5rem;
                  clear: both;
                  font-weight: 400;
                  color: #212529;
                  text-align: inherit;
                  white-space: nowrap;
                  background-color: transparent;
                  border: 0;
                  :hover {
                    color: #16181b;
                    text-decoration: none;
                    background-color: #f8f9fa;
                  }
                `}
                to="/settings/profile"
              >
                <BiUser className="mr-2" />
                {language === "nepali" ? "मेरो प्रोफाइल" : "My Profile"}
              </Link>

              <Dropdown.Item
                onClick={() => {
                  handleShowChangePasswordModal();
                }}
              >
                <CgPassword className="mr-2" />
                {language === "nepali"
                  ? "पासवर्ड परिवर्तन गर्नुहोस्"
                  : "Change password"}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => logoutUser()}>
                <FiLogOut className="mr-2" />
                {language === "nepali" ? "लगआउट" : "Logout"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* <LanguageSelector /> */}
        </div>
        {showChangePasswordModal && (
          <ChangePasswordModal
            show={showChangePasswordModal}
            handleCloseChangePasswordModal={handleCloseChangePasswordModal}
            showChangePasswordModal={showChangePasswordModal}
          />
        )}
      </div>
    </div>
  );
};

export default connect(null, { logoutUser })(Header);
