import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { isNotEmpty } from "../../../_helpers/methods";
import FormGroup from "../../../components/FormGroup";

import { yupResolver } from "@hookform/resolvers/yup";
import { VscLoading } from "react-icons/vsc";
import { useLocation, useParams } from "react-router-dom";
import { collectorSchema } from "../../../_helpers/schema-validation";
import { fetchNakas } from "../../../_redux/slices/naka";
import {
  fetchThekdarRepresentative,
  patchThekdarRepresentative,
  postThekdarRepresentative,
} from "../../../_redux/slices/thekdarCompany";
import { fetchWards } from "../../../_redux/slices/ward";

const UserForm = ({ id, setShowUserForm }) => {
  const { contractorId, page } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.users.loading);
  const [selectedKhola, setSelectedKhola] = useState(
    isNotEmpty(id) ? id?.khola_id : null
  );
  const { loading, results } = useSelector(
    (state) => state.thekdar.thekdarRepresentative
  );
  const { wards } = useSelector((state) => state.wards);
  const { nakas } = useSelector((state) => state.naka);
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(collectorSchema(isNotEmpty(id))),
    mode: "onBlur",
    defaultValue: {
      ...(isNotEmpty(id)
        ? {
            khola: id.khola_id,
            naka: id.naka_id,
          }
        : {}),
    },
  });

  const onSubmit = async (data) => {
    console.log("data", data);
    try {
      let body = { ...data };
      body.contractor = contractorId;
      body.role = type;
      if (!Boolean(body.password)) delete body.password;
      const formData = new FormData();

      if (body.signature) {
        formData.append("signature", body?.signature?.file);
        delete body.signature;
      }
      for (const [key, value] of Object.entries(body)) {
        formData.append(key, value);
      }

      isNotEmpty(id)
        ? dispatch(patchThekdarRepresentative(formData, id.id, setShowUserForm))
        : dispatch(postThekdarRepresentative(formData, setShowUserForm));
    } catch (error) {
      alert(error);
    }
  };

  const options = wards?.map((khola) => {
    return {
      value: khola.id,
      label: khola.name_np,
    };
  });
  const nakaOptions = nakas?.map((naka) => {
    return {
      value: naka.id,
      label: naka.name_np,
    };
  });
  useEffect(() => {
    dispatch(fetchThekdarRepresentative(page, contractorId));
  }, [dispatch, page, contractorId]);

  useEffect(() => {
    dispatch(fetchWards());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchNakas(page, selectedKhola));
  }, [page, dispatch, selectedKhola]);
  return (
    <Card>
      <Card.Body>
        <h5 className="text-primary">
          {isNotEmpty(id)
            ? "ठेकेदार प्रतिनिधि सम्पादन"
            : "नयाँ ठेकेदार प्रतिनिधि"}
        </h5>
        <Row className="mt-4" as={Form} onSubmit={handleSubmit(onSubmit)}>
          <Col md={4}>
            <FormGroup.Text
              id="fullname_np"
              register={register}
              label="नाम"
              defaultValue={isNotEmpty(id) ? id?.fullname_np : ""}
              errors={errors}
              disabled={isLoading}
            />
          </Col>
          <Col md={4}>
            <FormGroup.Text
              id="phone"
              register={register}
              as="tel"
              pattern={"[0-9]{10}"}
              label="फोन"
              defaultValue={isNotEmpty(id) ? id?.user?.phone : ""}
              errors={errors}
              disabled={isLoading}
            />
          </Col>
          <Col md={4}>
            <FormGroup.Text
              id="email"
              label="इमेल "
              as="email"
              register={register}
              defaultValue={isNotEmpty(id) ? id?.user?.email : ""}
              errors={errors}
              disabled={isLoading}
            />
          </Col>
          <Col md={4}>
            <FormGroup.Text
              id="username"
              register={register}
              label="Username"
              defaultValue={isNotEmpty(id) ? id?.user.username : ""}
              errors={errors}
              disabled={isLoading}
            />
          </Col>
          <Col md={4}>
            <FormGroup.Password
              id="password"
              label="पासवर्ड "
              register={register}
              errors={errors}
              x
              disabled={isLoading}
            />
          </Col>
          <Col md={4}>
            {type === "patake" && (
              <FormGroup.Select
                id="khola"
                label="खोला "
                setValue={setValue}
                options={options}
                onChange={(v) => setSelectedKhola(v.value)}
                errors={errors}
                disabled={isLoading}
                defaultValue={isNotEmpty(id) ? id?.khola_id : ""}
              />
            )}
          </Col>
          <Col md={4}>
            {type === "patake" && (
              <FormGroup.Select
                id="naka"
                label="नाका "
                setValue={setValue}
                options={nakaOptions}
                errors={errors}
                disabled={isLoading}
                defaultValue={isNotEmpty(id) ? id?.naka_id : ""}
              />
            )}
          </Col>
          <Col md={4}>
            <FormGroup.File
              label="Signature"
              id="signature"
              setValue={setValue}
              defaultValue={isNotEmpty(id) ? id?.signature : ""}
            />
          </Col>
          <Col md={12} className="d-flex justify-content-end">
            <Button variant="success" type="submit" disabled={isLoading}>
              {isLoading ? <VscLoading className="spin" /> : "पेश गर्नुहोस"}
            </Button>
            <Button
              variant="danger"
              className="ml-2"
              onClick={() => setShowUserForm(false)}
              disabled={isLoading}
            >
              रद्द गर्नुहोस
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default UserForm;
