import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';

//
import { isNotEmpty } from '../../../_helpers/methods';

//
import FormGroup from '../../../components/FormGroup';

//
import { postWards, patchWard } from '../../../_redux/slices/ward';

/**
 *
 *
 */
const WardForm = ({ id, setShowWardForm }) => {
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		isNotEmpty(id)
			? dispatch(patchWard(data, id?.id, setShowWardForm))
			: dispatch(postWards(data, setShowWardForm));
	};

	return (
		<Card>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Card.Body>
					<h5 className="text-primary">
						{isNotEmpty(id) ? 'वडा सम्पादन' : 'नयाँ वडा'}
					</h5>
					<Row className="mt-4">
						<Col md={4}>
							<FormGroup.Text
								id="name_np"
								label="नाम"
								register={register}
								error={errors.name_np}
								defaultValue={isNotEmpty(id) ? id?.name_np : ''}
								required
							/>
						</Col>
						

						<Col md={12} className="d-flex justify-content-end">
							<Button variant="success" type="submit">
								पेश गर्नुहोस
							</Button>
							<Button
								variant="danger"
								className="ml-2"
								onClick={() => setShowWardForm(false)}
							>
								रद्द गर्नुहोस
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Form>
		</Card>
	);
};

export default WardForm;
