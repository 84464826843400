import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiError, setUiMessage } from "./ui";
import { errorMessageHandler } from "../../_helpers/methods";

export const initialState = {
  count: 0,
  loading: false,
  fiscalYears: [],
  activeFiscalYear: null,
};

const fiscalSlice = createSlice({
  name: "fiscalyear",
  initialState,
  reducers: {
    setFiscalYear: (state, action) => {
      state.fiscalYears = action.payload?.results;
      state.count = action.payload?.count;
    },
    addFiscalYear: (state, action) => {
      state.fiscalYears = [action.payload, ...state.fiscalYears];
      state.count++;
    },
    editFiscalYear: (state, action) => {
      let tempFiscalYears = [];
      tempFiscalYears = state.fiscalYears?.filter(
        (fy) => fy?.id !== action.payload.id
      );
      state.fiscalYears = [action.payload, ...tempFiscalYears];
    },
    setActiveFiscalYear: (state, action) => {
      let tempFiscalYears = [];
      tempFiscalYears = state.fiscalYears?.map((fy) => {
        if (fy?.id === action.payload.id) {
          return { ...fy, is_active: true };
        } else {
          return { ...fy, is_active: false };
        }
      });
      state.fiscalYears = tempFiscalYears;
      state.activeFiscalYear = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    removeFiscalYear: (state, action) => {
      state.fiscalYears = state.fiscalYears.filter(
        (fy) => fy.id !== action.payload
      );
      --state.count;
    },
  },
});

// Actions generated from the slice
const {
  setFiscalYear,
  addFiscalYear,
  editFiscalYear,
  setActiveFiscalYear,
  setLoading,
  removeFiscalYear,
} = fiscalSlice.actions;

// export The reducer
const fiscalReducer = fiscalSlice.reducer;

export default fiscalReducer;

// Actions
export const getFiscalYears =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await fetchWrapper.get(
        `/fiscal-year/?limit=10&offset=${(page - 1) * 10}`
      );
      dispatch(setFiscalYear(response?.data));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

export const getactiveFiscalYear = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.get("/fiscal-year/active/");
    dispatch(setActiveFiscalYear(response?.data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const postFiscalYear = (data, callback) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.post("/fiscal-year/", data);
    dispatch(addFiscalYear(response?.data));
    dispatch(setUiMessage(response?.message));
    dispatch(setLoading(false));
    callback();
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(errorMessageHandler(error)));
  }
};

export const patchFiscalYear = (id, data, callback) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.patch(`/fiscal-year/${id}/`, data);
    dispatch(editFiscalYear(response?.data));
    dispatch(setUiMessage(response?.message));

    dispatch(setLoading(false));
    callback();
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(errorMessageHandler(error)));
  }
};

export const activateFiscalYear = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.patch(`/fiscal-year/${id}/`, {
      is_active: true,
    });
    dispatch(setActiveFiscalYear(response?.data));
    dispatch(setUiMessage(response?.message));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(errorMessageHandler(error)));
  }
};

export const deleteFiscalYear = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await fetchWrapper.delete(`/fiscal-year/${id}/`);
    dispatch(removeFiscalYear(id));
    dispatch(setUiMessage("Fiscal Year deleted Successfully"));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(errorMessageHandler(error)));
  }
};
