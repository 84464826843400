import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	loading: false,
	message: null,
	errors: null,
};

// A slice

const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		loadingUi: (state, action) => {
			state.loading = true;
		},
		stopLoadingUi: (state, action) => {
			state.loading = false;
		},
		clearMessage: (state, action) => {
			state.message = null;
		},
		setMessage: (state, action) => {
			try {
				state.message = {
					error: false,
					message: action?.payload,
				};
			} catch (err) {
				state.message = {
					error: true,
				};
			}
		},
		setError: (state, action) => {
			try {
				state.message = {
					error: true,
					message: action?.payload,
				};
			} catch (err) {
				state.message = {
					error: true,
				};
			}
		},
	},
});

const { loadingUi, stopLoadingUi, clearMessage, setMessage, setError } =
	uiSlice.actions;

export const uiSelector = (state) => state.ui;

const uiReducer = uiSlice.reducer;

export default uiReducer;

// Actions
export const startUiLoading = () => async (dispatch) => {
	dispatch(loadingUi());
};

export const stopUiLoading = () => async (dispatch) => {
	dispatch(stopLoadingUi());
};

export const clearUiMessage = () => async (dispatch) => {
	dispatch(clearMessage());
};

export const setUiError = (errorMessage) => async (dispatch) => {
	dispatch(setError(errorMessage));
};

export const setUiMessage = (message) => async (dispatch) => {
	dispatch(setMessage(message));
};
