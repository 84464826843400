/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { Fragment, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AiOutlineCalendar } from "react-icons/ai";
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";
import ReactSelect from "react-select/creatable";
import NepaliDatePicker from "./DatePicker";
import { ADToBS } from "./DatePicker/AdToBsConverter/AdBsConverter";

const FormGroup = ({ label, id, children, errors = {} }) => {
  const getErrorMessage = (errors) => {
    if (errors && Object.keys(errors).length > 0 && errors[id]) {
      if (typeof errors[id]?.message === "string")
        return <p className="text-danger font-italic">{errors[id]?.message}</p>;
      if (Array.isArray(errors[id])) {
        return errors[id].map((msg) => (
          <p className="text-danger font-italic">{msg?.message}</p>
        ));
      }
      if (errors[id]?.message?.message)
        return (
          <p className="text-danger font-italic">
            {errors[id]?.message?.message}
          </p>
        );
    }
  };
  return (
    <div className="form-group">
      {label && <label htmlFor={id}>{label}</label>}
      {children}
      {Object.keys(errors)?.length > 0 && (
        <Fragment>{getErrorMessage(errors)}</Fragment>
      )}
    </div>
  );
};

// text, email, number, url, tel
const Text = ({
  as = "text",
  id,
  register,
  label,
  errors,
  onChange = null,
  required = false,
  ...rest
}) => {
  return (
    <FormGroup id={id} label={label} errors={errors}>
      <input
        type={as}
        {...(register ? { ...register(id, { required }) } : {})}
        {...(onChange && { onChange: (e) => onChange(e) })}
        {...rest}
      />
    </FormGroup>
  );
};

// password
const Password = ({ id, label, register = null, errors }) => {
  const [type, setType] = React.useState("password");
  return (
    <FormGroup id={id} label={label} errors={errors}>
      <div className="password">
        <input
          type={type}
          minLength={8}
          // pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
          className=""
          autoComplete="new-password"
          {...(register ? { ...register(id) } : {})}
        />
        {type === "password" && (
          <span className="password-toggle" onClick={() => setType("text")}>
            <FaEye />
          </span>
        )}
        {type === "text" && (
          <span className="password-toggle" onClick={() => setType("password")}>
            <FaEyeSlash />
          </span>
        )}
      </div>
    </FormGroup>
  );
};
const PasswordUpdated = ({ id, label, register = null, errors }) => {
  const [type, setType] = React.useState("password");
  return (
    <FormGroup id={id} label={label} errors={errors}>
      <div className="password">
        <input
          type={type}
          className=""
          {...(register ? { ...register(id) } : {})}
        />
        {type === "password" && (
          <span className="password-toggle" onClick={() => setType("text")}>
            <FaEye />
          </span>
        )}
        {type === "text" && (
          <span className="password-toggle" onClick={() => setType("password")}>
            <FaEyeSlash />
          </span>
        )}
      </div>
    </FormGroup>
  );
};

// textarea
const TextArea = ({ id, register, label, errors, ...rest }) => {
  return (
    <FormGroup id={id} label={label} errors={errors}>
      <textarea {...(register ? { ...register(id) } : {})} {...rest}></textarea>
    </FormGroup>
  );
};

// wysiwyg editor
const EditorComponent = ({
  id,
  setValue,
  defaultValue,
  label,
  errors,
  ...rest
}) => {
  return (
    <FormGroup id={id} label={label} errors={errors}>
      <EditorComp
        id={id}
        setValue={setValue}
        {...(defaultValue && { defaultValue })}
      />
    </FormGroup>
  );
};

// radio
export const Radio = ({
  id,
  label,
  radios = [],
  register = null,
  setValue,
  errors,
  onChange,
  defaultValue,
}) => {
  return (
    <FormGroup id={id} label={label} errors={errors}>
      <div
        className="d-flex flex-wrap align-items-center"
        style={{ gap: "2rem" }}
      >
        {radios?.map((radio) => (
          <div
            key={radio?.id}
            className="d-flex  align-items-center"
            style={{ gap: "1rem" }}
          >
            <input
              type="radio"
              name={String(id)}
              id={radio?.id}
              hidden
              onChange={(e) => {
                setValue && setValue(id, radio?.id);
                onChange && onChange(e, radio?.id);
              }}
              defaultChecked={defaultValue === radio?.id}
            />
            <label htmlFor={radio?.id} className="radio-input"></label>
            <label className="text-sm" htmlFor={radio?.id}>
              {radio?.label}
            </label>
          </div>
        ))}
      </div>
      <input type="text" {...(register ? { ...register(id) } : {})} hidden />
    </FormGroup>
  );
};

// checkbox
const Checkbox = ({ id, label, checkboxes = [], register, errors }) => {
  return (
    <FormGroup id={id} label={label} errors={errors}>
      <div>
        {checkboxes?.map((checkbox) => (
          <div key={checkbox?.id} className="d-flex align-items-center">
            <input
              type="checkbox"
              id={checkbox?.id}
              {...(register ? { ...register(checkbox?.id) } : {})}
              defaultChecked={checkbox?.defaultChecked}
              hidden
            />
            <label htmlFor={checkbox?.id} className="checkbox-label mr-4" />
            <label htmlFor={checkbox?.id} className="text-sm">
              {checkbox?.label}
            </label>
          </div>
        ))}
      </div>
    </FormGroup>
  );
};

// month - month :period
const Period = ({ id, label, setValue, errors, defaultValue, register }) => {
  const options = [
    { label: "बैसाख", value: 1 },
    { label: "जेष्ठ", value: 2 },
    { label: "असार", value: 3 },
    { label: "श्रावण", value: 4 },
    { label: "भाद्र", value: 5 },
    { label: "असोज", value: 6 },
    { label: "कार्तिक", value: 7 },
    { label: "मंसिर", value: 8 },
    { label: "पौस", value: 9 },
    { label: "माघ", value: 10 },
    { label: "फाल्गुन", value: 11 },
    { label: "चैत्र", value: 12 },
  ];
  const [period, setPeriod] = React.useState([null, null]);
  useEffect(() => {
    if (defaultValue && setValue) {
      setValue(id, defaultValue);
      setPeriod(defaultValue);
    }
  }, [defaultValue, id, setValue]);
  React.useEffect(() => {
    setValue(id, period);
  }, [period, setValue, id]);

  return (
    <FormGroup id={id} label={label} errors={errors}>
      <div
        className="d-flex align-items-center"
        style={{ columnGap: "0.5rem" }}
      >
        <ReactSelect
          className="flex-grow-1"
          options={options}
          onChange={(v) => {
            setPeriod((prev) => [v.value, prev[1]]);
            setValue(id, period);
          }}
          defaultValue={
            defaultValue?.length === 2 &&
            options.find((opt) => opt.value === defaultValue[0])
          }
          placeholder={"छान्नुहोस् "}
          isSearchable={false}
        />{" "}
        ---
        <ReactSelect
          className="flex-grow-1"
          options={options}
          onChange={(v) => {
            setPeriod((prev) => [prev[0], v.value]);
            setValue(id, period);
          }}
          defaultValue={
            defaultValue?.length === 2 &&
            options.find((opt) => opt.value === defaultValue[1])
          }
          placeholder={"छान्नुहोस् "}
          isSearchable={false}
        />
      </div>
    </FormGroup>
  );
};

// file -> image and audio
const File = ({
  id,
  label,
  setValue,
  errors,
  accept = "image",
  isMulti = false,
  placeholder,
  defaultValue,
}) => {
  const [file, setFile] = React.useState(null);

  function handleFileChange(e) {
    if (e.target.files && e.target.files?.length > 0) {
      if (!isMulti) {
        setFile({
          name: e.target.files[0].name,
          type: e.target.files[0].type,
          file: e.target.files[0],
          url: URL.createObjectURL(e.target.files[0]),
        });
        setValue &&
          setValue(id, {
            name: e.target.files[0].name,
            type: e.target.files[0].type,
            file: e.target.files[0],
            url: URL.createObjectURL(e.target.files[0]),
          });
      } else {
        let files = [];
        for (let f of e.target.files) {
          files.push({
            name: f?.name,
            type: f?.type,
            file: f,
            url: URL.createObjectURL(f),
          });
        }
        setValue(id, files);
      }
    }
  }
  return (
    <FormGroup
      id={id}
      label={
        <span>
          {label} {placeholder && <i>{placeholder}</i>}
        </span>
      }
      errors={errors}
    >
      <div className="file-input">
        <input
          type="file"
          id={String(id)}
          onChange={handleFileChange}
          accept={
            accept === "image"
              ? "image/*"
              : accept === "audio"
              ? "audio/*"
              : accept
          }
          multiple={isMulti}
          hidden
        />
        <label htmlFor={String(id)} className="file-input--input">
          <p>Select File {isMulti && <i>(multiple)</i>} </p>
        </label>
        {(file || defaultValue) && accept === "image" && (
          <div className="file-input--preview">
            <img
              className="file-input--preview-image"
              src={file?.url || defaultValue}
              alt={file?.name}
            />
            {!defaultValue && (
              <button
                className="file-input--preview-close"
                onClick={() => {
                  setFile(null);
                  setValue && setValue(id, null);
                }}
              >
                <FaTimes className="text-white" />
              </button>
            )}
          </div>
        )}
        {file && accept === "audio" && <audio controls src={file?.url}></audio>}
      </div>
    </FormGroup>
  );
};

// select
const Select = ({
  id,
  label,
  defaultValue,
  isMulti = false,
  className,
  options = [],
  register = null,
  setValue,
  errors,
  placeholder,
  isSearchable = false,
  onChange = null,
  isLoading = true,
  onCreate = null,
  required = false,
}) => {
  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null);
  useEffect(() => {
    if (defaultValue && setValue) {
      let defValue;
      if (isMulti) {
        defValue = options?.filter((opt) => defaultValue?.includes(opt?.value));
      } else {
        defValue = options?.find((opt) => opt?.value === defaultValue);
      }
      setValue && setValue(id, defValue?.value);
      setSelectedValue(defValue);
    }
  }, [defaultValue, options, setValue, id, isMulti]);

  function onCreateOption(value) {
    if (onCreate) {
      onCreate(value);
    }
  }

  return (
    <FormGroup id={id} label={label} errors={errors}>
      <div className="d-block react-select-component">
        <ReactSelect
          defaultValue={
            defaultValue
              ? options?.find(
                  (opt) => String(opt?.value) === String(defaultValue)
                )
              : {}
          }
          value={selectedValue}
          isMulti={isMulti}
          options={options}
          isSearchable={isSearchable}
          className={className}
          onChange={(v) => {
            setSelectedValue(v);
            if (isMulti) {
              setValue &&
                setValue(
                  id,
                  v?.map((selected) => selected?.value)
                );
              onChange && onChange(v);
            } else {
              setValue && setValue(id, v?.value);
              onChange && onChange(v);
            }
          }}
          placeholder={placeholder || "छान्नुहोस् "}
          isLoading={isLoading}
          onCreateOption={onCreateOption}
          required={required}
        />
        <input
          type="text"
          {...(register ? { ...register(id) } : {})}
          defaultValue={defaultValue}
          hidden
        />
      </div>
    </FormGroup>
  );
};

// nepali date
const DateComponent = ({
  id,
  label,
  errors,
  defaultValue,
  setValue,
  onChange,
  placeholder,
}) => {
  const [date, setDate] = useState(defaultValue ? ADToBS(defaultValue) : "");
  const onDateChange = (name, dateInMilli, bsDate, adDate) => {
    if (bsDate) {
      setDate(bsDate);
      setValue && setValue(id, adDate);
      if (onChange) {
        onChange(bsDate, adDate);
      }
    }
  };
  return (
    <FormGroup id={id} label={label} errors={errors}>
      <StyledDateContainer>
        <div className="np_input_div">
          <NepaliDatePicker
            id={id}
            onChange={onDateChange}
            value={date}
            placeholder={placeholder}
          />
          <AiOutlineCalendar className="icon" />
        </div>
      </StyledDateContainer>
    </FormGroup>
  );
};

export default Object.assign({
  Text,
  TextArea,
  File,
  Select,
  Checkbox,
  Radio,
  Password,
  PasswordUpdated,
  Period,
  Editor: EditorComponent,
  Date: DateComponent,
});

function EditorComp({
  editorStyle,
  defaultValue,
  disabled,
  onChange,
  setValue,
  id,
}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  console.log(editorState.getCurrentContent());
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    let tempContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setValue(id, tempContent);
    onChange && onChange(editorState);
  };
  useEffect(() => {
    if (defaultValue) {
      const contentBlock = htmlToDraft(defaultValue);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const defaultEditorState = EditorState.createWithContent(contentState);
        setEditorState(defaultEditorState);
      }
    }
  }, [defaultValue]);
  return (
    <StyledEditorContainer style={editorStyle}>
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        readOnly={disabled}
      />
    </StyledEditorContainer>
  );
}

const StyledEditorContainer = styled.div`
  border: 1px solid #b8bcb8;
  border-radius: 5px;
  padding: 0 0.75rem;
  background: white;
  min-height: 295px;
`;

const StyledDateContainer = styled.div`
  .np_input_div {
    position: relative;
    .icon {
      position: absolute;
      font-size: 16px;
      top: 50%;
      transform: translate(0, -50%);
      right: 10px;
      pointer-events: none;
      cursor: pointer;
    }
  }
`;
