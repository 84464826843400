import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Card, Row, Col, Button, Form } from "react-bootstrap";

//
import { isNotEmpty } from "../../../_helpers/methods";

//
import FormGroup from "../../../components/FormGroup";

//
// import { postWards, patchWard } from "../../../_redux/slices/ward";
import { postNakas, patchNaka } from "../../../_redux/slices/naka";
import { useParams } from "react-router-dom";

/**
 *
 *
 */
const NakaForm = ({ id, setShowWardForm }) => {
  const dispatch = useDispatch();
  const { kholaId } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    isNotEmpty(id)
      ? dispatch(patchNaka(data, id?.id, setShowWardForm))
      : dispatch(postNakas({ ...data, khola: kholaId }, setShowWardForm));
  };

  return (
    <Card>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card.Body>
          <h5 className="text-primary">
            {isNotEmpty(id) ? "नाका सम्पादन" : "नयाँ नाका"}
          </h5>
          <Row className="mt-4">
            <Col md={4}>
              <FormGroup.Text
                id="name_np"
                label="नाम"
                register={register}
                error={errors.name_np}
                defaultValue={isNotEmpty(id) ? id?.name_np : ""}
                required
              />
            </Col>
            <Col md={4}>
              <FormGroup.Text
                id="phone_en"
                label="फोन"
                register={register}
                error={errors.phone_en}
                defaultValue={isNotEmpty(id) ? id?.phone_en : ""}
                required
              />
            </Col>
            <Col md={4}>
              <FormGroup.Text
                id="ward_np"
                label="वडा"
                register={register}
                error={errors.ward_np}
                defaultValue={isNotEmpty(id) ? id?.ward_np : ""}
                required
              />
            </Col>
            <Col md={4}>
              <FormGroup.Text
                id="address_np"
                label="ठेगाना"
                register={register}
                error={errors.address_np}
                defaultValue={isNotEmpty(id) ? id?.address_np : ""}
                required
              />
            </Col>

            <Col md={12} className="d-flex justify-content-end">
              <Button variant="success" type="submit">
                पेश गर्नुहोस
              </Button>
              <Button
                variant="danger"
                className="ml-2"
                onClick={() => setShowWardForm(false)}
              >
                रद्द गर्नुहोस
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Form>
    </Card>
  );
};

export default NakaForm;
