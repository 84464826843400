/** @jsxRuntime classic */
/** @jsx jsx */
// eslint-disable-next-line
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { Fragment } from "react";
import { Table } from "react-bootstrap";
import {
  AiOutlineCheckCircle,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlinePauseCircle,
  AiOutlinePlus,
} from "react-icons/ai";
import { MdPrint } from "react-icons/md";
import { VscLoading } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { useAlert } from "../../hooks/alert";
import Filter from "../Filter";

const CustomTable = ({
  headers = [],
  data = [],
  filter = null,
  isLoading,
  tableStyleOverwrite,
  tableClassName,
  withSN = false,
  leftContent,
}) => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div>{leftContent && leftContent}</div>
        {filter && <Filter {...filter} />}
      </div>
      <StyledTable
        hover
        css={tableStyleOverwrite}
        className={["", tableClassName].join(" ")}
      >
        <thead>
          {headers?.map((head, i) => (
            <tr key={i}>
              {withSN && <th rowSpan={data?.length}>S.N.</th>}
              {head?.map((h) => (
                <th
                  key={h.key}
                  rowSpan={h?.rowSpan}
                  colSpan={h?.colSpan}
                  {...(h?.headerStyleOverwrite
                    ? { css: h?.headerStyleOverwrite }
                    : {})}
                  className={["p-2", h?.className ? h?.className : ""].join(
                    " "
                  )}
                >
                  {h?.title}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {!isLoading && data && data?.length === 0 && (
          <tbody>
            <tr>
              <td
                colSpan={
                  headers?.flat()?.length - headers?.length + (withSN ? 2 : 1)
                }
              >
                <div
                  style={{
                    height: "150px",
                    display: "grid",
                    placeContent: "center",
                  }}
                >
                  <p className="text-center text-secondary">
                    कुनै परिणाम फेला परेन
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {isLoading ? (
          <tbody>
            <tr>
              {/* need to modify this if the table header has more than 2 rows */}
              <td colSpan={headers?.flat()?.length - headers?.length + 1}>
                <div
                  style={{
                    height: "100px",
                    display: "grid",
                    placeContent: "center",
                  }}
                >
                  <VscLoading fontSize={40} className="spin" />
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {data?.map((d, i) => (
              <tr key={data?._id ?? i}>
                {withSN && <td>{i + 1}</td>}
                {headers
                  ?.flat()
                  ?.sort((u, v) => u?.order - v?.order)
                  .map((head) =>
                    head?.hasChildren ? null : (
                      <td
                        key={head.key}
                        {...(head?.dataStyleOverwrite
                          ? { css: head?.dataStyleOverwrite }
                          : {})}
                        className={[
                          "px-2",
                          head?.dataClassName ? head.dataClassName : "",
                        ].join(" ")}
                      >
                        {head?.render ? (
                          head?.render(d, i)
                        ) : d[head.key] ? (
                          d[head.key]
                        ) : (
                          <small className="text-secondary">N/A</small>
                        )}
                      </td>
                    )
                  )}
              </tr>
            ))}
          </tbody>
        )}
      </StyledTable>
    </div>
  );
};

export default CustomTable;

const StyledTable = styled(Table)`
  border: 1px solid #ccc;
  border-radius: 8px !important;
  .btn-action {
    padding: 0.375rem 0.5rem !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.25rem;
  }
`;

const ActionContainer = ({ path, children }) => {
  if (!!path) {
    return <Link to={path}>{children}</Link>;
  }
  return <Fragment>{children}</Fragment>;
};

const Add = ({ path, onClick }) => {
  return (
    <ActionContainer path={path}>
      <span
        role="button"
        className="btn btn-primary btn-sm"
        title="Add"
        {...(onClick ? { onClick } : {})}
      >
        <AiOutlinePlus className="text-white" />
      </span>
    </ActionContainer>
  );
};
const Edit = ({ path, onClick }) => {
  return (
    <ActionContainer path={path}>
      <span role="button" title="Edit" {...(onClick ? { onClick } : {})}>
        <AiOutlineEdit className="text-secondary" />
      </span>
    </ActionContainer>
  );
};
const View = ({ path, onClick }) => {
  return (
    <ActionContainer path={path}>
      <span role="button" title="View" {...(onClick ? { onClick } : {})}>
        <AiOutlineEye className="text-primary" />
      </span>
    </ActionContainer>
  );
};
const Suspend = ({ path, message, onClick, noAlert = false }) => {
  const handleAlert = useAlert();
  return (
    <ActionContainer path={path}>
      <span
        role="button"
        title="Suspend"
        {...(!noAlert && onClick
          ? {
              onClick: () =>
                handleAlert({
                  message: message || "के तपाई रद्द गर्न  चाहनुहुन्छ?",
                  OK: onClick,
                  type: "danger",
                }),
            }
          : { onClick })}
      >
        <AiOutlinePauseCircle className="text-danger" />
      </span>
    </ActionContainer>
  );
};
const Activate = ({ path, message, onClick, noAlert = false }) => {
  const handleAlert = useAlert();
  return (
    <ActionContainer path={path}>
      <span
        role="button"
        title="Activate"
        {...(!noAlert && onClick
          ? {
              onClick: () =>
                handleAlert({
                  message: message || "के तपाई पुन सुचारु गर्न चाहनुहुन्छ?",
                  OK: onClick,
                  type: "success",
                }),
            }
          : { onClick })}
        className="bg-success text-white px-1 rounded"
        style={{ fontSize: "12px" }}
      >
        <AiOutlineCheckCircle className="text-white" /> activate
      </span>
    </ActionContainer>
  );
};
const Delete = ({ path, onClick, title, message, noAlert = false }) => {
  const handleAlert = useAlert();
  return (
    <ActionContainer path={path}>
      <span
        role="button"
        title={title ? title : "Delete"}
        {...(!noAlert && onClick
          ? {
              onClick: () =>
                handleAlert({
                  message: message || "के तपाई मेटाउन चाहनुहुन्छ?",
                  OK: onClick,
                  type: "danger",
                }),
            }
          : { onClick })}
      >
        <AiOutlineDelete className="text-danger" />
      </span>
    </ActionContainer>
  );
};

const Print = ({ path, onClick }) => {
  return (
    <ActionContainer path={path}>
      <span role="button" title="View" {...(onClick ? { onClick } : {})}>
        <MdPrint className="text-primary" />
      </span>
    </ActionContainer>
  );
};

export const Action = Object.assign({
  Edit,
  View,
  Suspend,
  Delete,
  Activate,
  Add,
  Print,
});
