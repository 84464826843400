import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Card, Row, Col, Button, Form } from "react-bootstrap";

//
import { isNotEmpty } from "../../../_helpers/methods";

//
import FormGroup from "../../../components/FormGroup";

//
import { postWards, patchWard } from "../../../_redux/slices/ward";
import { ThekdarSchema } from "../../../_helpers/schema-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  patchThekdarCompany,
  postThekdarCompany,
} from "../../../_redux/slices/thekdarCompany";

/**
 *
 *
 */
const ThekdarCompanyForm = ({ id, setShowWardForm }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ThekdarSchema()),
    mode: "onBlur",
  });
  const onSubmit = (data) => {
    isNotEmpty(id)
      ? dispatch(patchThekdarCompany(id.id, data, setShowWardForm))
      : dispatch(postThekdarCompany(data, setShowWardForm));
  };

  return (
    <Card>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card.Body>
          <h5 className="text-primary">
            {isNotEmpty(id) ? "ठेकदार सम्पादन" : "नयाँ ठेकदार "}
          </h5>
          <Row className="mt-4">
            <Col md={4}>
              <FormGroup.Text
                id="name"
                label="नाम"
                register={register}
                errors={errors}
                defaultValue={isNotEmpty(id) ? id?.name : ""}
              />
            </Col>
            <Col md={4}>
              <FormGroup.Text
                id="address"
                label="ठेगाना"
                register={register}
                errors={errors}
                defaultValue={isNotEmpty(id) ? id?.address : ""}
              />
            </Col>
            <Col md={4}>
              <FormGroup.Text
                id="pan_no"
                label="प्यान "
                type="number"
                register={register}
                errors={errors}
                defaultValue={isNotEmpty(id) ? id?.pan_no : ""}
              />
            </Col>
            <Col md={4}>
              <FormGroup.Select
                id="type"
                label="प्रकार"
                setValue={setValue}
                errors={errors}
                defaultValue={isNotEmpty(id) ? id?.type : ""}
                options={[
                  { value: "patake", label: "पटके" },
                  { value: "bhaisi_paada", label: "पाडा भैसी " },
                  { value: "haatbazaar", label: "हाट बजार" },
                  { value: "sadak_marmat", label: "सडक मर्मत" },
                  { value: "kabaad", label: "कबाड " },
                ]}
              />
            </Col>

            <Col md={12} className="d-flex justify-content-end">
              <Button variant="success" type="submit">
                पेश गर्नुहोस
              </Button>
              <Button
                variant="danger"
                className="ml-2"
                onClick={() => setShowWardForm(false)}
              >
                रद्द गर्नुहोस
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Form>
    </Card>
  );
};

export default ThekdarCompanyForm;
