import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./users";
import languageReducer from "./languages";
import uiReducer from "./ui";
import fiscalReducer from "./fiscalyear";
import wardReducer from "./ward";
import nagarpalikaReducer from "./nagarpalika";
import customerReducer from "./customer";
import faqReducer from "./faq";
import supportReducer from "./support";
import nakaReducer from "./naka";
import patakeReducer from "./patake";
import thekdarReducer from "./thekdarCompany";
import particularsReducer from "./unitCost";
import hatbazaarReducer from "./hatbazaar";
import vaisipadaReducer from "./vaisipada";
import sadakmarmatReducer from "./sadakmarmat";
import kabaadReducer from "./kabad";

const rootReducer = combineReducers({
  users: userReducer,
  language: languageReducer,
  ui: uiReducer,
  fiscal: fiscalReducer,
  wards: wardReducer,
  nagarpalika: nagarpalikaReducer,
  customer: customerReducer,
  faq: faqReducer,
  support: supportReducer,
  naka: nakaReducer,
  patake: patakeReducer,
  thekdar: thekdarReducer,
  unitCost: particularsReducer,
  hatbazaar: hatbazaarReducer,
  vaisipada: vaisipadaReducer,
  sadakmarmat: sadakmarmatReducer,
  kabad: kabaadReducer,
});

export default rootReducer;
