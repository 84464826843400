import { createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiMessage, setUiError } from "./ui";
import { errorMessageHandler } from "../../_helpers/methods";

export const initialState = {
  thekdarCompany: {
    results: [],
    count: 0,
    loading: false,
  },
  thekdarRepresentative: {
    results: [],
    count: 0,
    loading: false,
  },
};

// A slice
const thekdarSlice = createSlice({
  name: "thekdar",
  initialState,
  reducers: {
    setThkedarCompanyLoading: (state, action) => {
      state.thekdarCompany.loading = action.payload;
    },
    setThkedarRepresemtativeLoading: (state, action) => {
      state.thekdarRepresentative.loading = action.payload;
    },
    getThekdarCompany: (state, action) => {
      state.thekdarCompany.results = action.payload.results;
      state.thekdarCompany.count = action.payload.count;
    },
    getThekdarRepresentative: (state, action) => {
      state.thekdarRepresentative.results = action.payload.results;
      state.thekdarRepresentative.count = action.payload.count;
    },

    createThekdarCompany: (state, action) => {
      state.thekdarCompany.results = [
        action.payload.data,
        ...state.thekdarCompany.results,
      ];
    },
    createThekdarRepresentative: (state, action) => {
      state.thekdarRepresentative.results = [
        action.payload.data,
        ...state.thekdarRepresentative.results,
      ];
    },

    deleteThekdarCompany: (state, action) => {
      const temp = state.thekdarCompany.results;

      const index = temp.findIndex((wd) => wd.id === action.payload);

      temp.splice(index, 1);

      state.thekdarCompany.results = temp;
    },

    deleteThekdarRepresentative: (state, action) => {
      const temp = state.thekdarRepresentative.results;

      const index = temp.findIndex((wd) => wd.id === action.payload);

      temp.splice(index, 1);

      state.thekdarRepresentative.results = temp;
    },

    editThekdarCompany: (state, action) => {
      const temp = state.thekdarCompany.results.filter(
        (wd) => wd.id !== action.payload.id
      );
      state.thekdarCompany.results = [...temp, action.payload];
    },
    editThekdarRepresentative: (state, action) => {
      const temp = state.thekdarCompany.results.filter(
        (wd) => wd.id !== action.payload.id
      );
      state.thekdarRepresentative.results = [...temp, action.payload];
    },
  },
});

// Actions generated from the slice
const {
  setThkedarCompanyLoading,
  setThkedarRepresemtativeLoading,
  getThekdarCompany,
  getThekdarRepresentative,
  createThekdarCompany,
  createThekdarRepresentative,
  deleteThekdarCompany,
  deleteThekdarRepresentative,
  editThekdarCompany,
  editThekdarRepresentative,
} = thekdarSlice.actions;

// export user selector to get the slice in any component
// export const thekdarSelector = (state) => state.nakas;

// export The reducer
const thekdarReducer = thekdarSlice.reducer;

export default thekdarReducer;

// Actions
export const fetchThekdarCompany =
  (page = 1) =>
  async (dispatch) => {
    try {
      const response = await fetchWrapper.get(
        `/contractor/?offset=${(page - 1) * 10}&limit=10`
      );
      dispatch(getThekdarCompany(response.data));
    } catch (error) {}
  };

export const postThekdarCompany = (data, showUserForm) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post("/contractor/", data);
    console.log("post resepose", response);
    showUserForm(false);
    dispatch(createThekdarCompany(response));
    dispatch(setUiMessage(response?.message));
  } catch (error) {
    dispatch(setUiError(errorMessageHandler(error)));
  }
};
export const patchThekdarCompany =
  (id, body, closeForm) => async (dispatch) => {
    try {
      const response = await fetchWrapper.patch(`/contractor/${id}/`, body);
      console.log(response);
      dispatch(editThekdarCompany(response?.data));
      dispatch(setUiMessage(response?.message));
      closeForm();
    } catch (error) {
      dispatch(setUiError(errorMessageHandler(error)));
    }
  };
export const removeThekdarCompany = (id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.delete(`/contractor/${id}/`);
    dispatch(deleteThekdarCompany(id));
    dispatch(setUiMessage(response.message));
  } catch (error) {
    dispatch(setUiError(errorMessageHandler(error)));
  }
};
export const fetchThekdarRepresentative =
  (page, contractorId) => async (dispatch) => {
    try {
      const response = await fetchWrapper.get(
        `/profile/?contractor=${contractorId}&offset=${
          (page - 1) * 10
        }&limit=10`
      );
      dispatch(getThekdarRepresentative(response.data));
    } catch (error) {}
  };

export const postThekdarRepresentative =
  (data, showUserForm) => async (dispatch) => {
    try {
      const response = await fetchWrapper.post("/profile/", data, true);
      showUserForm(false);
      dispatch(createThekdarRepresentative(response));
      dispatch(setUiMessage(response?.message));
    } catch (error) {
      dispatch(setUiError(errorMessageHandler(error)));
    }
  };

export const patchThekdarRepresentative =
  (body, id, closeForm) => async (dispatch) => {
    try {
      const response = await fetchWrapper.patch(`/profile/${id}/`, body);
      dispatch(editThekdarRepresentative(response?.data));
      dispatch(setUiMessage(response?.message));
      closeForm();
    } catch (error) {
      dispatch(setUiError(errorMessageHandler(error)));
    }
  };

export const removethekdarRepresentative = (id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.delete(`/profile/${id}/`);
    dispatch(deleteThekdarRepresentative(id));
    dispatch(setUiMessage(response.message));
  } catch (error) {
    dispatch(setUiError(errorMessageHandler(error)));
  }
};
