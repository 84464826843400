import React, { Fragment, useMemo } from 'react';
import { Pagination } from 'react-bootstrap';
import { nepaliNumber } from '../_helpers/methods';

const PaginationComp = ({ total, page, setPage, perPage = 10 }) => {
	const totalCount = useMemo(
		() => Array.from({ length: Math.ceil(total / perPage) }, (v, i) => i + 1),
		[perPage, total]
	);
	const lastPage = Math.ceil(total / perPage);

	return (
		<div className="pagination-wrapper d-flex justify-content-start align-items-center">
			<Pagination>
				<Pagination.First
					disabled={page <= 5}
					onClick={() => (page > 1 ? setPage(page - 5) : null)}
				/>
				<Pagination.Prev
					disabled={page <= 1}
					onClick={() => (page > 1 ? setPage(page - 1) : null)}
				/>
				{page < 5 ? (
					totalCount
						.filter((count, index) => index < 5)
						.map((count) => (
							<Pagination.Item
								active={count === parseInt(page)}
								key={count}
								onClick={() => setPage(count)}
							>
								{nepaliNumber(count)}
							</Pagination.Item>
						))
				) : (
					<>
						<Pagination.Item>...</Pagination.Item>
						<Pagination.Item onClick={() => setPage(page - 2)}>
							{nepaliNumber(page - 2)}
						</Pagination.Item>
						<Pagination.Item onClick={() => setPage(page - 1)}>
							{nepaliNumber(page - 1)}
						</Pagination.Item>

						<Pagination.Item active>{nepaliNumber(page)}</Pagination.Item>

						{page + 1 <= lastPage && (
							<Pagination.Item
								onClick={() =>
									page < totalCount.length ? setPage(page + 1) : null
								}
							>
								{nepaliNumber(page + 1)}
							</Pagination.Item>
						)}

						{page + 2 <= lastPage && (
							<Fragment>
								<Pagination.Item
									onClick={() =>
										page < totalCount.length ? setPage(page + 2) : null
									}
								>
									{nepaliNumber(page + 2)}
								</Pagination.Item>
								<Pagination.Item>...</Pagination.Item>
							</Fragment>
						)}
					</>
				)}

				<Pagination.Next
					disabled={page >= totalCount.length}
					onClick={() => (page <= totalCount.length ? setPage(page + 1) : null)}
				/>
				<Pagination.Last
					disabled={page > lastPage - 5}
					onClick={() => (page < totalCount.length ? setPage(page + 5) : null)}
				/>
			</Pagination>
		</div>
	);
};

export default PaginationComp;
