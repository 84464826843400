import { createSlice } from "@reduxjs/toolkit";
import Fetch from "../../_helpers/fetch";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { jwtCheck, setToken } from "../../_helpers/jwt-check";
import { errorMessageHandler } from "../../_helpers/methods";
import { setUiError, setUiMessage } from "./ui";

export const initialState = {
  loading: false,
  hasErrors: false,
  users: [],
  user: null,
  isLoggedIn: jwtCheck() ? true : false,
  count: 0,
};

// A slice
const usersSlice = createSlice({
  name: "users",
  initialState,

  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    updateUser: (state, action) => {},
    setUserLogin: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    setUserLogout: (state, action) => {
      state.user = null;
      state.isLoggedIn = false;
    },

    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setUsers: (state, action) => {
      state.users = action.payload.results;
      state.count = action.payload.count;
    },

    addUser: (state, action) => {
      state.users = [action.payload, ...state.users];
    },

    editUser: (state, action) => {
      state.users = state.users.map((user) => {
        if (user.id === action.payload.id) {
          return action.payload;
        } else return user;
      });
    },
    removeUser: (state, action) => {
      state.users = state.users.filter((user) => user.id !== action.payload);
    },
  },
});

// Actions generated from the slice
const {
  setUser,
  setUserLogin,
  setUserLogout,
  startLoading,
  stopLoading,
  setUsers,
  addUser,
  editUser,
  removeUser,
} = usersSlice.actions;

// export user selector to get the slice in any component
export const usersSelector = (state) => state.users;
export const userSelector = (state) => state.users.user;

// export The reducer
const userReducer = usersSlice.reducer;

export default userReducer;

// Actions

export const createUser = (User) => async (dispatch) => {
  try {
    dispatch(addUser(User));
  } catch (error) {}
};

export const patchWardUser = (data, id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.patch(`/profile/${id}/`, data);
    dispatch(updateUser(response.data));
    dispatch(setUiMessage(response.message));
  } catch (error) {
    let errorMessage = error.error?.non_field_error || error.message;
    dispatch(setUiError(errorMessage));
  }
};
export const patchITOAdminUser = (data, id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.patch(`/ito/${id}/`, data);
    dispatch(updateUser(data));
    dispatch(setUiMessage(response.message));
  } catch (error) {
    let errorMessage = error.error?.non_field_error || error.message;
    dispatch(setUiError(errorMessage));
  }
};

export const loginUser = (credentials, history) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await Fetch(process.env.REACT_APP_BACKEND_URL + "/login/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(credentials),
    });
    let res = JSON.parse(await response.text());
    if (response.ok) {
      let accessToken = {
        token: await res?.data?.access,
      };
      let refreshToken = {
        token: await res?.data?.refresh,
      };
      let role = await res?.data?.role;
      setToken(accessToken, refreshToken, role);
      dispatch(setUserLogin(res?.data));
      dispatch(stopLoading());
      history.push("/");
    } else throw res;
  } catch (err) {
    console.log(err, "login error");
    dispatch(stopLoading());
    dispatch(setUiError(errorMessageHandler(err)));
  }
};

export const getUserData = () => async (dispatch) => {
  try {
    const user = await fetchWrapper.get(`/user/me/`);
    console.log(user);
    const body = {
      ...user,
      profile: {
        name: user?.name,
        address: user?.address,
      },
    };
    dispatch(setUser(body));
  } catch (err) {
    if (err.code === 401) {
      dispatch(setUserLogout());
    }
  }
};
export const getUsers =
  (page = 1, limit = 10, contractorId) =>
  async (dispatch) => {
    try {
      dispatch(startLoading(true));
      const response = await fetchWrapper.get(
        `/profile/?page=${page}&limit=${limit}${
          contractorId ? `&role=${contractorId}` : ""
        }`
      );
      dispatch(setUsers(response?.data));
      dispatch(stopLoading());
    } catch (error) {
      dispatch(stopLoading());

      console.log(error);
    }
  };

export const postUsers = (body, closeUserForm) => async (dispatch) => {
  try {
    dispatch(startLoading(true));
    const response = await fetchWrapper.post("/profile/", body, true);
    dispatch(addUser(response?.data));
    dispatch(setUiMessage(response?.message));
    closeUserForm();
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    dispatch(setUiError(errorMessageHandler(error)));
  }
};

export const updateUser = (body, id, palikaCloseForm) => async (dispatch) => {
  try {
    dispatch(startLoading(true));

    const response = await fetchWrapper.patch(`/profile/${id}/`, body, true);
    dispatch(editUser(response?.data));
    dispatch(setUiMessage(response?.message));
    palikaCloseForm();
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());

    dispatch(setUiError(errorMessageHandler(error)));
  }
};

export const activateUser = (id) => async (dispatch) => {
  try {
    dispatch(startLoading(true));

    const response = await fetchWrapper.patch(`/profile/${id}/`, {
      is_active: true,
    });
    dispatch(editUser(response?.data));
    dispatch(setUiMessage(response?.message));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());

    dispatch(setUiError(errorMessageHandler(error)));
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch(startLoading(true));
    let response = await fetchWrapper.delete(`/profile/${id}/`);
    dispatch(setUiMessage(response?.message));
    dispatch(removeUser(id));
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    dispatch(setUiError(errorMessageHandler(error)));
  }
};

export const logoutUser = () => async (dispatch) => {
  localStorage.removeItem("user");
  localStorage.removeItem("userRefresh");
  localStorage.removeItem("role");

  dispatch(setUserLogout());
};
