import { refreshCheck, setToken } from './jwt-check';

let isRefreshing = false;

const originalFetch = fetch;
export default function Fetch() {
	if (isRefreshing) return;
	let self = this;
	let args = arguments;
	return originalFetch.apply(self, args).then(async function (data) {
		if (data.status === 401) {
			if (refreshCheck()) {
				isRefreshing = true;
				return originalFetch(
					process.env.REACT_APP_BACKEND_URL + '/auth/refresh-tokens',
					{
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ refreshToken: refreshCheck() }),
					}
				).then(async (response) => {
					let tokens = JSON.parse(await response.text());
					console.log(tokens);
					setToken(
						{ token: await tokens?.access },
						{ token: await tokens?.refresh }
					);
					isRefreshing = false;
					let [url, requestOptions] = args;
					return originalFetch(url, {
						...requestOptions,
						headers: {
							...requestOptions.headers,
							Authorization: `Bearer ${await tokens.access.token}`,
						},
					});
				});
			} else {
				localStorage.removeItem('user');
				localStorage.removeItem('userRefresh');
				return data;
			}
		}
		return data;
	});
}
