import { authHeader } from './auth-header';
import { toast } from 'react-toastify';
import Fetch from './fetch';

let COMMON_URL = process.env.REACT_APP_BACKEND_URL;

async function get(url) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	try {
		let URL = COMMON_URL + url;
		let response = await Fetch(URL, requestOptions);
		let handledResponse = await handleResponse(response);
		// console.log(handledResponse, 'HandleResponse')
		return handledResponse;
	} catch (err) {
		console.log(err, 'ERROR');
		return err;
	}
}

function post(url, body, contentType) {
	let user = JSON.parse(localStorage.getItem('user'));

	const requestOptions = {
		method: 'POST',
		headers: contentType
			? {
					Authorization: `Bearer ${user.token}`,
			  }
			: authHeader(),
		body: contentType ? body : JSON.stringify(body),
	};

	return Fetch(COMMON_URL + url, requestOptions).then(handleResponse);
}

function put(url, body) {
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(body),
	};
	return Fetch(COMMON_URL + url, requestOptions).then(handleResponse);
}

function patch(url, body, contentType) {
	let user = JSON.parse(localStorage.getItem('user'));
	try {
		const requestOptions = {
			method: 'PATCH',
			headers: contentType
				? {
						Authorization: `Bearer ${user.token}`,
				  }
				: authHeader(),
			body: contentType ? body : JSON.stringify(body),
		};
		const response = Fetch(COMMON_URL + url, requestOptions).then(
			handleResponse
		);
		return response;
	} catch (error) {
		console.log(error, 'error');
	}
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader(),
	};
	return Fetch(COMMON_URL + url, requestOptions).then(handleResponse);
}

// helper functions
function handleResponse(response) {
	if (response.status === 403) {
		toast.error('तपाईलाई यस कार्यको अनुमति छैन।');
		return Promise.reject(response);
	}
	if (response.status === 401) {
		toast.error('माफ गर्नुहोस ! तपाईले प्रविष्ट गर्नु भएको विवरण मिलेन |');
		return Promise.reject(response);
	}
	if (response.status === 500) {
		toast.error('Something went wrong!');
		return Promise.reject(response);
	}

	return response.text().then((text) => {
		try {
			const data = text && JSON.parse(text);
			if (!response.ok) {
				const error = (data && data) || response;
				return Promise.reject(error);
			}
			return data;
		} catch (e) {
			return Promise.reject(response);
		}
	});
}

export const fetchWrapper = {
	get,
	post,
	put,
	patch,
	delete: _delete,
};
