import { lazy, Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

//
import { PrivateRoute } from "./auth-route";

//
import { clearUiMessage } from "./_redux/slices/ui";
import { getUserData } from "./_redux/slices/users";

//
import Loader from "./_elements/Loader";
import Layout from "./layout/Layout";

//
import "lightgallery.js/dist/css/lightgallery.css";
import "react-toastify/dist/ReactToastify.css";
import { getNagarpalika } from "./_redux/slices/nagarpalika";
import "./components/Form/index.css";
import AlertContextProvider from "./hooks/alert";
import "./sass/style.scss";
// import { getactiveFiscalYear } from './_redux/slices/fiscalyear';
import { LightgalleryProvider } from "react-lightgallery";
import { getactiveFiscalYear } from "./_redux/slices/fiscalyear";
import Hatbazaar from "./pages/Hatbazaar";
import Nakas from "./pages/Settings/Nakas";
import Khola from "./pages/Settings/Ward";
import Naka from "./pages/Settings/Ward/Naka";
import UnitCost from "./pages/Settings/particulars";
import ThekdarCompany from "./pages/Settings/thekdarCompany";
import ThekdarRepresentative from "./pages/Settings/thekdarCompany/thekdarRepresentative";
import VaisiPaada from "./pages/VaisiPaada";

import Kabad from "./pages/kabad";
import SadakMarmat from "./pages/sadakMarmat";

// auth
const Login = lazy(() => import("./pages/auth/Login"));
const SignUp = lazy(() => import("./pages/auth/SignUp"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));

const Report = lazy(() => import("./pages/Report"));
const Support = lazy(() => import("./pages/Support"));
const FAQ = lazy(() => import("./pages/Faq"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const PatakeAadesh = lazy(() => import("./pages/PatakeAadesh"));
const CustomerView = lazy(() => import("./pages/PatakeAadesh/CustomerView"));
// Settings
const Settings = lazy(() => import("./pages/Settings/Settings"));
const FiscalYear = lazy(() => import("./pages/Settings/FiscalYear"));
const Profile = lazy(() => import("./pages/Settings/Profile/Profile"));
// const UnitCost = lazy(() => import("./pages/Settings/UnitCost"));
// users
const Collector = lazy(() => import("./pages/Settings/Karmachari"));
const NagarpalikaDetails = lazy(() =>
  import("./pages/NagarpalikaDetails/NagarpalikaDetails")
);

//

/**
 *
 */
function App() {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.ui.message);
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const nagarpalika = useSelector((state) => state.nagarpalika?.nagarpalika);

  useEffect(() => {
    dispatch(getNagarpalika());
    dispatch(getactiveFiscalYear());
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserData());
      // dispatch(getactiveFiscalYear());
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    if (message) {
      if (message.error === false) {
        toast.success(message.message);
        dispatch(clearUiMessage());
      } else if (message) {
        toast.error(message.message);
        dispatch(clearUiMessage());
      } else {
        toast.success(message);
      }
    }
  }, [message, dispatch]);

  return (
    <Suspense fallback={<Loader />}>
      <Helmet>
        <title>{`निर्माण सामाग्री व्यवस्थापन प्रणाली | ${nagarpalika?.name_np}`}</title>
      </Helmet>
      <LightgalleryProvider>
        <Router>
          <AlertContextProvider>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/reset-password" component={ResetPassword} />
              {/* <Route exact path="/privacy-policy" component={PrivacyPolicy} /> */}
              <Route
                path="/"
                render={({ match }) => <RootRoutes basePath={match.path} />}
              />
            </Switch>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </AlertContextProvider>
        </Router>
      </LightgalleryProvider>
    </Suspense>
  );
}

export default App;

const RootRoutes = () => (
  <Layout>
    <PrivateRoute
      exact
      path="/"
      component={Dashboard}
      accessGrantedRoles={["collector", "superuser"]}
    />

    <PrivateRoute
      exact
      path="/patake/:pageNum"
      component={PatakeAadesh}
      accessGrantedRoles={["collector", "superuser"]}
    />
    <PrivateRoute
      exact
      path="/hatbazaar/:pageNum"
      component={Hatbazaar}
      accessGrantedRoles={["collector", "superuser"]}
    />
    <PrivateRoute
      exact
      path="/vaisipaada/:pageNum"
      component={VaisiPaada}
      accessGrantedRoles={["collector", "superuser"]}
    />
    <PrivateRoute
      exact
      path="/sadak/:pageNum"
      component={SadakMarmat}
      accessGrantedRoles={["collector", "superuser"]}
    />

    <PrivateRoute
      exact
      path="/kabad/:pageNum"
      component={Kabad}
      accessGrantedRoles={["collector", "superuser"]}
    />

    <PrivateRoute
      exact
      path="/customer-history/:id/:pageNum"
      component={CustomerView}
      accessGrantedRoles={["collector", "superuser"]}
    />

    <PrivateRoute
      exact
      path="/reports"
      component={Report}
      accessGrantedRoles={["collector", "superuser"]}
    />
    <PrivateRoute
      exact
      path="/settings"
      component={Settings}
      accessGrantedRoles={["superuser"]}
    />
    <PrivateRoute
      exact
      path="/settings/fiscalyear/:pageNum"
      component={FiscalYear}
      accessGrantedRoles={["superuser"]}
    />
    <PrivateRoute
      exact
      path="/settings/thekedar/:pageNum"
      component={ThekdarCompany}
      accessGrantedRoles={["superuser"]}
    />

    <PrivateRoute
      exact
      path="/settings/profile"
      component={Profile}
      accessGrantedRoles={["collector", "superuser"]}
    />
    <PrivateRoute
      exact
      path="/support"
      component={Support}
      accessGrantedRoles={["collector", "superuser"]}
    />
    <PrivateRoute
      exact
      path="/faq"
      component={FAQ}
      accessGrantedRoles={["collector", "superuser"]}
    />
    <PrivateRoute
      exact
      path="/settings/nagarpalika"
      component={NagarpalikaDetails}
      accessGrantedRoles={["superuser"]}
    />

    <PrivateRoute
      exact
      path="/settings/representative/:contractorId/:pageNum/"
      component={ThekdarRepresentative}
      accessGrantedRoles={["superuser"]}
    />

    <PrivateRoute
      exact
      path="/settings/collector/:pageNum/"
      component={Collector}
      accessGrantedRoles={["superuser"]}
    />
    <PrivateRoute
      exact
      path="/settings/unit-cost/:pageNum"
      component={UnitCost}
      accessGrantedRoles={["superuser"]}
    />
    <PrivateRoute
      exact
      path="/settings/wards/:pageNum"
      component={Khola}
      accessGrantedRoles={["superuser"]}
    />
    <PrivateRoute
      exact
      path="/settings/nakas/:pageNum"
      component={Nakas}
      accessGrantedRoles={["superuser"]}
    />
    <PrivateRoute
      exact
      path="/settings/naka/:kholaId/:pageNum"
      component={Naka}
      accessGrantedRoles={["superuser"]}
    />
  </Layout>
);
