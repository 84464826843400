import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiError, setUiMessage } from "./ui";

const initialState = {
  isLoading: false,
  patakes: [],
  patake: null,
  count: 0,
  totalQuantity: 0,
};

const patakeSlice = createSlice({
  name: "patake",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setpatakes: (state, action) => {
      state.patakes = action.payload.results;
      state.count = action.payload.count;
      state.totalQuantity = action.payload.total_quantity;
    },
    setpatake: (state, action) => {
      state.patake = action.payload;
    },
    addpatake: (state, action) => {
      state.patakes = [action.payload, ...state.patakes];
      state.count++;
    },
    removepatake: (state, action) => {
      state.patakes = state.patakes.filter(
        (item) => item._id !== action.payload
      );
      state.count--;
    },
    editpatake: (state, action) => {
      state.patakes = state.patakes.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
  },
});

export default patakeSlice.reducer;

const {
  setLoading,
  setpatake,
  setpatakes,
  addpatake,
  removepatake,
  editpatake,
} = patakeSlice.actions;

// Actions

export const getpatakes =
  (query = null) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await fetchWrapper.get(`/patake/${query ? query : ""}`);
      const countResponse = await fetchWrapper.get(
        `/patake/count/${query ? query : ""}`
      );
      console.log(countResponse);
      dispatch(
        setpatakes({
          ...response?.data,
          total_quantity: countResponse?.data?.count,
        })
      );
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setUiError(error?.message));
    }
  };

export const getpatake = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.get(`/patake/${id}/`);
    dispatch(setpatake(response?.data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};

export const postpatake = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.post("/patake/", data);
    dispatch(addpatake(response?.data));
    dispatch(setUiMessage(response?.message));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};

export const patchpatake = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.patch(`/patake/${id}/`, data);
    dispatch(editpatake(response?.data));
    dispatch(setUiMessage(response?.message));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};

export const deletepatake = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.delete(`/patake/${id}/`);
    dispatch(removepatake(id));
    dispatch(setUiMessage(response?.message));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};
