export function jwtCheck() {
	let user;

	try {
		user = JSON.parse(localStorage.getItem('user'));
	} catch (error) {
		user = null;
	}
	// const isExpired = new Date(Date.now) > new Date(user?.token?.expires);
	// if (isExpired) {
	// 	return 'expired';
	// }
	if (
		user &&
		user?.token
		// && !isExpired
	) {
		console.log('token', user?.token);
		return user?.token;
	} else {
		return false;
	}
}

export function refreshCheck() {
	let refresh;
	try {
		refresh = JSON.parse(localStorage.getItem('userRefresh'));
	} catch (error) {
		refresh = null;
	}
	if (refresh && refresh?.token?.token) {
		return refresh?.token?.token;
	} else {
		return false;
	}
}

export const setToken = (accessToken, refreshToken, role) => {
	console.log('tokens', accessToken, refreshToken);
	localStorage.setItem('user', JSON.stringify(accessToken));
	localStorage.setItem('userRefresh', JSON.stringify(refreshToken));
	role && localStorage.setItem('role', JSON.stringify(role));
};
