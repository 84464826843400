import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { setUiMessage } from './ui';

export const initialState = {
	loading: false,
	nagarpalika: null,
};

// A slice
const nagarpalikaSlice = createSlice({
	name: 'nagarpalika',
	initialState,
	reducers: {
		setNagarpalika: (state, action) => {
			state.nagarpalika = action.payload;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
	},
});

// Actions generated from the slice
const { setNagarpalika, setLoading } = nagarpalikaSlice.actions;

// export The reducer
const nagarpaikaReducer = nagarpalikaSlice.reducer;

export default nagarpaikaReducer;

// Actions

export const getNagarpalika = () => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const response = await fetchWrapper.get('/nagarpalika/');
		dispatch(setNagarpalika(response?.data?.results[0]));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};

export const editNagarpalika = (id, data) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const response = await fetchWrapper.patch(`/nagarpalika/${id}/`, data);
		dispatch(setNagarpalika(response?.data));
		dispatch(setUiMessage(response?.message));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};
