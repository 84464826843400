import { Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, Fragment, useEffect } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

//
import WardForm from "./WardForm";
//
import PaginationComp from "../../../components/Pagination";
import CustomTable, { Action } from "../../../components/Table";

//
import { fetchWards, removeWard } from "../../../_redux/slices/ward";

//
const Khola = () => {
  const { pageNum } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showWardForm, setShowWardForm] = useState(null);
  const [page, setPage] = useState(pageNum ? pageNum : 1);
  const { loading, wards, count } = useSelector((state) => state.wards);

  useEffect(() => {
    dispatch(fetchWards(page));
  }, [dispatch, page]);

  const headers = [
    [
      {
        key: "name_np",
        title: "नाम",
      },
      {
        key: "actions",
        title: "कार्यहरु",
        render: (ward) => {
          return (
            <div
              className="d-flex align-items-center"
              style={{ columnGap: "0.5rem" }}
            >
              <Action.View
                onClick={() => history.push(`/settings/naka/${ward.id}/1`)}
              />
              <Action.Edit onClick={() => setShowWardForm(ward)} />
              <Action.Delete
                className="cursor-pointer"
                onClick={() => {
                  dispatch(removeWard(ward.id));
                }}
              />
            </div>
          );
        },
      },
    ],
  ];
  return (
    <Fragment>
      {showWardForm && (
        <WardForm id={showWardForm} setShowWardForm={setShowWardForm} />
      )}
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="text-primary">खोला</h4>
            <div>
              <Button onClick={() => setShowWardForm(true)}>
                नयाँ खोला बनाउनुहोस
              </Button>
            </div>
          </div>
          <div className="pt-4">
            <CustomTable headers={headers} data={wards} isLoading={loading} />
            <PaginationComp total={count} page={page} setPage={setPage} />
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default Khola;
