import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { errorMessageHandler } from "../../_helpers/methods";
import { setUiError, setUiMessage } from "./ui";

const initialState = {
  isLoading: false,
  particulars: [],
  count: 0,
};

const particularsSlice = createSlice({
  name: "particulars",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setParticulars: (state, action) => {
      state.particulars = action.payload.results;
      state.count = action.payload.count;
    },
    createParticulars: (state, action) => {
      state.particulars = [action.payload, ...state.particulars];
    },
    deleteParticular: (state, action) => {
      const temp = state.particulars.filter((wd) => wd.id === action.payload);
      state.particulars = [...temp, action.payload];
    },
    editParticular: (state, action) => {
      console.log("payload", action.payload);
      let tempParticulars = [...state.particulars];
      state.particulars = tempParticulars.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
    },
  },
});

const particularsReducer = particularsSlice.reducer;

export default particularsReducer;

const {
  setParticulars,
  setLoading,
  createParticulars,
  deleteParticular,
  editParticular,
} = particularsSlice.actions;

// Actions

export const getparticular = (type) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.get(`/particulars/?type=${type}`);
    dispatch(setParticulars(response?.data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};
export const getparticulars = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.get(`/particulars/`);
    dispatch(setParticulars(response?.data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};

export const postParticular = (data) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post(`/particulars/`, data);

    dispatch(createParticulars(response?.data));
    dispatch(setUiMessage(response.message));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};

export const removeParticular = (id) => async (dispatch) => {
  console.log("id of", id);
  try {
    const response = await fetchWrapper.delete(`/particulars/${id}/`);

    dispatch(deleteParticular(id));
    dispatch(setUiMessage(response.message));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};

export const patchParticular = (body, id, closeForm) => async (dispatch) => {
  try {
    const response = await fetchWrapper.patch(`/particulars/${id}/`, body);
    console.log("edited data", response?.data);
    dispatch(editParticular(response?.data));
    dispatch(setUiMessage(response?.message));
    closeForm(false);
  } catch (error) {
    dispatch(setUiError(errorMessageHandler(error)));
  }
};
