import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

import PaginationComp from "../../../components/Pagination";
import CustomTable, { Action } from "../../../components/Table";
import UserForm from "./ThekdarRepresentativeForm";
import {
  fetchThekdarRepresentative,
  removethekdarRepresentative,
} from "../../../_redux/slices/thekdarCompany";

const ThekdarRepresentative = () => {
  const { pageNum, contractorId } = useParams();
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");
  const dispatch = useDispatch();

  const [showUserForm, setShowUserForm] = useState(null);
  const [page, setPage] = useState(pageNum);
  const { loading, results, count } = useSelector(
    (state) => state.thekdar.thekdarRepresentative
  );

  useEffect(() => {
    dispatch(fetchThekdarRepresentative(page, contractorId));
  }, [dispatch, page]);

  const headers = [
    [
      {
        key: "fullname_np",
        title: "नाम",
      },
      {
        key: "username",
        title: "Username",
        render: (collector) => collector?.user?.username,
      },
      {
        key: "phone",
        title: "फोन",
        render: (collector) => collector?.user?.phone,
      },
      {
        key: "email",
        title: "इमेल",
        render: (collector) => collector?.user?.email,
      },

      // {
      //   key: "address",
      //   title: "ठेगाना",
      // },

      {
        key: "actions",
        title: "कार्यहरु",
        render: (collector) => (
          <div
            className="d-flex align-items-center"
            style={{ columnGap: "0.5rem" }}
          >
            <Action.Edit onClick={() => setShowUserForm(collector)} />
            <Action.Delete
              title="delete"
              message="Are you sure you want to delete this?"
              onClick={() => {
                dispatch(removethekdarRepresentative(collector?.id));
              }}
            />
          </div>
        ),
      },
    ],
  ];

  if (type === "patake") {
    const updatedHeaders = [
      ...headers[0].slice(0, 3),
      {
        key: "khola_name",
        title: "खोला",
        render: (collector) => collector?.khola_name,
      },
      {
        key: "naka",
        title: "नाका  ",
        render: (collector) => collector?.naka,
      },

      ...headers[0].slice(3),
    ];
    headers[0] = updatedHeaders;
  }

  return (
    <Fragment>
      {showUserForm && (
        <UserForm id={showUserForm} setShowUserForm={setShowUserForm} />
      )}
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="text-primary">ठेकेदार प्रतिनिधि</h3>
            <div className="d-flex" style={{ columnGap: "1rem" }}>
              {/* <Export url={'/collector/'} /> */}
              <Button onClick={() => setShowUserForm(true)}>
                नयाँ ठेकेदार प्रतिनिधि थप्नुहोस
              </Button>
            </div>
          </div>
          <div className="pt-4">
            <div>
              <CustomTable headers={headers} data={results} isLoading={false} />
              <PaginationComp page={page} setPage={setPage} total={count} />
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default ThekdarRepresentative;
