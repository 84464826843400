import NepaliDate from 'nepali-date-converter';
import { nepaliNumbers } from '../_datas';

export function isNotEmpty(value) {
	if (['string', 'number', 'object'].includes(typeof value) && value !== null) {
		return true;
	}
	return false;
}

export function normalizeSnakeCase(str) {
	return str
		.split('_')
		.map((st) => st.charAt(0).toUpperCase() + st.slice(1))
		.join(' ');
}

export function englishNumberConverter(numString) {
	let englishNumber = '';
	for (let i = 0; i < numString.length; i++) {
		let num = numString[i];
		let englishNum = nepaliNumbers.indexOf(num);
		if (englishNum < 0) {
			englishNumber += num;
		} else {
			englishNumber += englishNum;
		}
	}
	return englishNumber;
}

export function nepaliNumberConverter(numString) {
	let nepaliNumber = '';
	for (let i = 0; i < numString?.length; i++) {
		if (nepaliNumbers.includes(numString[i])) {
			nepaliNumber += numString[i];
		} else if (nepaliNumbers[numString[i]]) {
			nepaliNumber += nepaliNumbers[numString[i]];
		} else {
			nepaliNumber += numString[i];
		}
	}
	return nepaliNumber;
}

export function getTodayMonth() {
	let todayMonth = new NepaliDate().format('M');
	todayMonth = String(todayMonth);
	if (todayMonth.length < 2) {
		return '0' + todayMonth;
	} else {
		return todayMonth;
	}
}

export function getTodayDay() {
	let todayDay = new NepaliDate().format('D');
	todayDay = String(todayDay);
	if (todayDay.length < 2) {
		return '0' + todayDay;
	} else {
		return todayDay;
	}
}

export const nepaliNumber = (number) => {
	const strNum = String(number);
	let strNepNum = '';
	for (let i = 0; i < strNum.length; i++) {
		strNepNum +=
			nepaliNumbers[parseInt(strNum.substr(i, 1))] ||
			(nepaliNumbers.includes(strNum.substr(i, 1)) && strNum.substr(i, 1)) ||
			'';
	}
	return strNepNum;
};

export function getDifference(a, b) {
	var i = 0;
	var j = 0;
	var result = '';
	let position;
	while (j < b.length) {
		if (a[i] !== b[j] || i === a.length) {
			result = b[j];
			position = j;
			break;
		} else i++;
		j++;
	}
	return { result, position };
}

export function normalizeHtml(str) {
	return (
		str &&
		str
			.replace(/&nbsp;|\u202F|\u00A0/g, ' ')
			.replace(/&lt;/g, 'ÿ') // < and <</p> causes a problem because 1st character is equal
			//and it always takes second one so this little hack prevents using  < at any cost
			.replace(/&gt;/g, '>')
			.replace(/&amp;/g, '&')
	);
}

function createRange(node, chars, range) {
	if (!range) {
		range = document.createRange();
		range.selectNode(node);
		range.setStart(node, 0);
	}

	if (chars.count === 0) {
		range.setEnd(node, chars.count);
	} else if (node && chars.count > 0) {
		if (node.nodeType === Node.TEXT_NODE) {
			if (node.textContent.length < chars.count) {
				chars.count -= node.textContent.length;
			} else {
				range.setEnd(node, chars.count);
				chars.count = 0;
			}
		} else {
			for (var lp = 0; lp < node.childNodes.length; lp++) {
				range = createRange(node.childNodes[lp], chars, range);

				if (chars.count === 0) {
					break;
				}
			}
		}
	}

	return range;
}

export function setCurrentCursorPosition(
	chars,
	preview = 'preview',
	insertCharacter
) {
	if (chars >= 0) {
		const selection = window.getSelection();

		let range = createRange(document.getElementById(preview).parentNode, {
			count: chars,
		});

		if (range) {
			range.collapse(false);
			selection.removeAllRanges();
			selection.addRange(range);
		}
	}
}

export function getCurrentCursorPosition() {
	const element = document.getElementById('preview');
	var caretOffset = 0;
	var doc = element.ownerDocument || element.document;
	var win = doc.defaultView || doc.parentWindow;
	var sel;
	if (typeof win.getSelection != 'undefined') {
		sel = win.getSelection();
		if (sel.rangeCount > 0) {
			var range = win.getSelection().getRangeAt(0);
			var preCaretRange = range.cloneRange();
			preCaretRange.selectNodeContents(element);
			preCaretRange.setEnd(range.endContainer, range.endOffset);
			caretOffset = preCaretRange.toString().length;
		}
	} else if ((sel = doc.selection) && sel.type !== 'Control') {
		var textRange = sel.createRange();
		var preCaretTextRange = doc.body.createTextRange();
		preCaretTextRange.moveToElementText(element);
		preCaretTextRange.setEndPoint('EndToEnd', textRange);
		caretOffset = preCaretTextRange.text.length;
	}
	return caretOffset;
}

export const debounce = (func, delay = 500) => {
	let timeout;
	return function (...args) {
		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => {
			func(...args);
		}, delay);
	};
};

export const urlPublicChecker = (url, isPublic = false) => {
	if (isPublic) {
		return '/public' + url;
	}
	return url;
};

export const insertSubStringAtString = (index, string, subString) => {
	let result = string.split('');
	result.splice(index, 0, subString);
	return result.join('');
};

export const errorMessageHandler = (error) => {
	if (isNotEmpty(error?.error)) {
		let errorMessage = ''
		let errorArrays = Object.values(error?.error);
		if (errorArrays.length) {
			errorMessage = errorArrays[0][0];
		}
		return errorMessage;
	}
	else {
		return error.message;
	}
}