import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiError, setUiMessage } from "./ui";

const initialState = {
  isLoading: false,
  supports: [],
  support: null,
  count: 0,
};

const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setsupports: (state, action) => {
      state.supports = action.payload.results;
      state.count = action.payload.count;
    },
    setsupport: (state, action) => {
      state.support = action.payload;
    },
    addsupport: (state, action) => {
      state.supports = [action.payload, ...state.supports];
      state.count++;
    },
    removesupport: (state, action) => {
      state.supports = state.supports.filter(
        (item) => item._id !== action.payload
      );
      state.count--;
    },
    editsupport: (state, action) => {
      state.supports = state.supports.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
  },
});

export default supportSlice.reducer;

const {
  setLoading,
  setsupport,
  setsupports,
  addsupport,
  removesupport,
  editsupport,
} = supportSlice.actions;

// Actions

export const getsupports = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.get("/support");
    dispatch(setsupports(response?.data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};

export const getsupport = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.get(`/support/${id}`);
    dispatch(setsupport(response?.data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};

export const postsupport = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.post("/support", data);
    dispatch(addsupport(response?.data));
    dispatch(setUiMessage(response?.message));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};

export const patchsupport = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.patch(`/support/${id}`, data);
    dispatch(editsupport(response?.data));
    dispatch(setUiMessage(response?.message));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};

export const deletesupport = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.delete(`/support/${id}`);
    dispatch(removesupport(id));
    dispatch(setUiMessage(response?.message));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};
