import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiError } from "./ui";

const initialState = {
  isLoading: false,
  results: [],
  count: 0,
  totalAmount: 0,
};

const kabaadSlice = createSlice({
  name: "vaisipada",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setkabaad: (state, action) => {
      state.results = action.payload.results;
      state.count = action.payload.count;
      state.totalAmount = action.payload.total_amount;
    },
  },
});

const kabaadReducer = kabaadSlice.reducer;
export default kabaadReducer;
const { setkabaad, setLoading } = kabaadSlice.actions;

// Actions

export const getKabad = (query) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.get(`/kabaad/${query ? query : ""}`);
    const countResponse = await fetchWrapper.get(
      `/kabaad/count/${query ? query : ""}`
    );
    dispatch(
      setkabaad({ ...response?.data, total_amount: countResponse?.data?.count })
    );
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};
