import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiError, setUiMessage } from "./ui";

const initialState = {
  isLoading: false,
  faqs: [],
  faq: null,
  count: 0,
};

const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setfaqs: (state, action) => {
      state.faqs = action.payload.results;
      state.count = action.payload.count;
    },
    setfaq: (state, action) => {
      state.faq = action.payload;
    },
    addfaq: (state, action) => {
      state.faqs = [action.payload, ...state.faqs];
      state.faq++;
    },
    removefaq: (state, action) => {
      state.faqs = state.faqs.filter((item) => item._id !== action.payload);
      state.count--;
    },
    editfaq: (state, action) => {
      state.faqs = state.faqs.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
  },
});

export default faqSlice.reducer;

const { setLoading, setfaq, setfaqs, addfaq, removefaq, editfaq } =
  faqSlice.actions;

// Actions

export const getfaqs = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.get("/faq");
    dispatch(setfaqs(response?.data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};

export const getfaq = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.get(`/faq/${id}`);
    dispatch(setfaq(response?.data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};

export const postfaq = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.post("/faq", data);
    dispatch(addfaq(response?.data));
    dispatch(setUiMessage(response?.message));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};

export const patchfaq = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.patch(`/faq/${id}`, data);
    dispatch(editfaq(response?.data));
    dispatch(setUiMessage(response?.message));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};

export const deletefaq = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await fetchWrapper.delete(`/faq/${id}`);
    dispatch(removefaq(id));
    dispatch(setUiMessage(response?.message));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setUiError(error?.message));
  }
};
