/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useContext } from 'react';
import { createContext, Fragment, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
	AiOutlineCheckCircle,
	AiOutlineCloseCircle,
	AiOutlineInfoCircle,
	AiOutlineWarning,
} from 'react-icons/ai';
import { FaCheck, FaTimes } from 'react-icons/fa';

const AlertContext = createContext({ handleAlert: null });

export function useAlert() {
	const alertContext = useContext(AlertContext);
	return alertContext.handleAlert;
}

const AlertContextProvider = ({ children }) => {
	const [show, setShow] = useState(false);
	const [alert, setAlert] = useState({
		message: 'Are you sure you want to delete this?', // message to show
		type: null, // info, success, danger, warning
		OK: null, // method to run when ok is clicked
		Cancel: null, // method to run when cancel is clicked
	});
	const handleAlert = ({ message, type, OK, Cancel }) => {
		setAlert({ message, type, OK, Cancel });
		setShow(true);
	};
	return (
		<Fragment>
			<AlertContext.Provider value={{ handleAlert }}>
				{children}
			</AlertContext.Provider>
			{show && (
				<Modal
					show={show}
					onHide={() => setShow(false)}
					css={css`
						.modal-content {
							border-radius: 32px;
						}
					`}
				>
					<Modal.Body className="py-5 d-flex flex-column justify-content-center align-items-center">
						{alert?.type === 'danger' && (
							<AiOutlineCloseCircle
								fontSize={80}
								className="mb-3 text-danger"
							/>
						)}
						{alert?.type === 'info' && (
							<AiOutlineInfoCircle fontSize={80} className="mb-3 text-info" />
						)}
						{alert?.type === 'warning' && (
							<AiOutlineWarning fontSize={80} className="mb-3 text-warning" />
						)}
						{alert?.type === 'success' && (
							<AiOutlineCheckCircle
								fontSize={80}
								className="mb-3 text-success"
							/>
						)}
						<h4
							className={[
								'text-center',
								alert?.type === 'danger' && 'text-danger',
								alert?.type === 'info' && 'text-info',
								alert?.type === 'warning' && 'text-warning',
								alert?.type === 'success' && 'text-success',
							].join(' ')}
						>
							{alert?.message}
						</h4>
					</Modal.Body>
					<Modal.Footer>
						<Button
							className="btn-sm"
							onClick={() => {
								alert.OK && alert.OK();
								setShow(false);
							}}
						>
							<FaCheck /> {alert?.type === 'danger' ? ' ठीक छ' : ' Okay'}
						</Button>
						<Button
							variant="secondary"
							className="btn-sm"
							onClick={() => {
								alert.Cancel && alert.Cancel();
								setShow(false);
							}}
						>
							<FaTimes /> {alert?.type === 'danger' ? ' रद्द' : ' Cancel'}
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Fragment>
	);
};

export default AlertContextProvider;
