import React from "react";
import { ADToBS } from "../../components/FormGroup/DatePicker/AdToBsConverter/AdBsConverter";
import moment from "moment";
import { Card, Button, Modal } from "react-bootstrap";
import CustomTable, { Action } from "../../components/Table";
import PaginationComp from "../../components/Pagination";
import { Fragment } from "react";
import { useSelector } from "react-redux";
function ShowParticular({ ShowParticular, particularName }) {
  const { title } = useSelector((state) => state.fiscal.activeFiscalYear);
  const particularHeaders = [
    [
      {
        key: "sn",
        title: "सि.नं. ",
      },
      {
        key: "name",
        title: "विवरण",
      },
      {
        key: "rate",
        title: "दर",
      },
      {
        key: "quantity",
        title: "परिमाण",
      },
      {
        key: "total",
        title: "कुल",
      },
    ],
  ];
  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center "
        style={{ gap: "20px" }}
      >
        <div>
          <img src={"/assets/images/nep_logo.png"} style={{ height: "70px" }} />
        </div>
        <div className="text-center">
          <h3 className="font-weight-bold ">
            जीतपुरसिमरा उपमहानगरपालिका <br />
            नगर कार्यपालिकाको कार्यालय{" "}
          </h3>
          <h4> जीतपुर,मधेश प्रदेश, नेपाल</h4>
          <h4 style={{ marginTop: "10px" }}> {particularName}</h4>
        </div>
      </div>
      <div
        className="d-flex justify-content-between"
        style={{ margin: "50px" }}
      >
        <div>
          <p>
            <span className="font-weight-bold">बिल नं </span> :{" "}
            {ShowParticular.bill_no}{" "}
          </p>
          <p>
            <span className="font-weight-bold">पान नं </span>:{" "}
            {ShowParticular.pan_no}{" "}
          </p>
        </div>
        <div>
          <p>
            {" "}
            <span className="font-weight-bold">क्रेताको नाम</span>:{" "}
            {ShowParticular.name}{" "}
          </p>
          <p>
            {" "}
            <span className="font-weight-bold">ठेगाना</span>: :{" "}
            {ShowParticular.address}{" "}
          </p>
        </div>
        <div>
          <p>
            <span className="font-weight-bold">आ.व. </span>: {title}:{" "}
          </p>
          <p>
            <span className="font-weight-bold">मिति</span>:{" "}
            {ADToBS(moment(ShowParticular?.date).format("YYYY-MM-DD"))}
          </p>
        </div>
      </div>
      <CustomTable
        headers={particularHeaders}
        data={ShowParticular.particular}
      />
    </>
  );
}

export default ShowParticular;
