import { Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, Fragment, useEffect } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

//
import WardForm from "./ThekdarCompanyForm";
//
import PaginationComp from "../../../components/Pagination";
import CustomTable, { Action } from "../../../components/Table";

import ThekdarCompanyForm from "./ThekdarCompanyForm";
import {
  fetchThekdarCompany,
  removeThekdarCompany,
} from "../../../_redux/slices/thekdarCompany";
import { render } from "@testing-library/react";

//
const ThekdarCompany = () => {
  const { pageNum } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showWardForm, setShowWardForm] = useState(null);
  const [page, setPage] = useState(pageNum ? pageNum : 1);
  const { loading, results, count } = useSelector(
    (state) => state.thekdar.thekdarCompany
  );
  console.log(results);
  useEffect(() => {
    dispatch(fetchThekdarCompany(page));
  }, [dispatch, page]);
  const types = {
    patake: "पटके",
    bhaisi_paada: "पाडा भैसी",
    haatbazaar: "हाट बजार",
    sadak_marmat: "सडक मर्मत",
    kabaad: "कबाड",
  };
  const headers = [
    [
      {
        key: "name",
        title: "नाम",
      },
      {
        key: "address",
        title: "ठेगाना",
      },
      {
        key: "pan_no",
        title: "प्यान नम्बर",
      },
      {
        key: "type",
        title: "प्रकार",
        render: (thekdar) => {
          return types[thekdar?.type];
        },
      },

      {
        key: "actions",
        title: "कार्यहरु",
        render: (thekdar) => {
          return (
            <div
              className="d-flex align-items-center"
              style={{ columnGap: "0.5rem" }}
            >
              <Action.View
                onClick={() =>
                  history.push(
                    `/settings/representative/${thekdar.id}/1?type=${thekdar.type}`
                  )
                }
              />
              <Action.Edit onClick={() => setShowWardForm(thekdar)} />
              <Action.Delete
                className="cursor-pointer"
                onClick={() => {
                  dispatch(removeThekdarCompany(thekdar.id));
                }}
              />
            </div>
          );
        },
      },
    ],
  ];
  return (
    <Fragment>
      {showWardForm && (
        <ThekdarCompanyForm
          id={showWardForm}
          setShowWardForm={setShowWardForm}
        />
      )}
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="text-primary">ठेकदार </h4>
            <div>
              <Button onClick={() => setShowWardForm(true)}>
                नयाँ ठेकदार बनाउनुहोस
              </Button>
            </div>
          </div>
          <div className="pt-4">
            <CustomTable headers={headers} data={results} isLoading={loading} />
            <PaginationComp total={count} page={page} setPage={setPage} />
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default ThekdarCompany;
