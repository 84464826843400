/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { upperData } from "./SidebarData";
import SidebarButtonRenderer from "./SidebarButtonRenderer";

const Sidebar = () => {
  const nagarpalika = useSelector((state) => state.nagarpalika.nagarpalika);
  const user = useSelector((state) => state.users?.user);
  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-top">
        <Link to="/">
          <img
            src={nagarpalika?.main_logo || "/assets/images/nep_logo.png"}
            className="logo"
            alt=""
          />
        </Link>
        <div>
          <h5>{nagarpalika?.name_np || nagarpalika?.name_en}</h5>
          <p
            css={css`
              font-size: 1.05rem;
            `}
          >
            {user?.role === "palikaUser"
              ? user?.department + " शाखा"
              : "नगर कार्यपालिकाको कार्यालय"}
          </p>
          <p
            css={css`
              font-size: 0.9rem;
            `}
          >
            {nagarpalika?.address_np || nagarpalika?.address_en},
            <span className="ml-1">
              {nagarpalika?.province_np || nagarpalika?.province_en}
            </span>
          </p>
        </div>
      </div>
      <hr
        css={css`
          margin: 0 auto;
          width: 90%;
          border-top-color: #dedede !important;
        `}
      />
      <h4
        css={css`
          text-align: center;
          padding: 0.5rem 0;
          color: black;
        `}
      >
        पटके शुल्क <br /> व्यवस्थापन प्रणाली
      </h4>
      <hr
        css={css`
          margin: 0 auto;
          width: 90%;
          border-top-color: #dedede !important;
        `}
      />
      <div className="menus">
        {upperData && <SidebarButtonRenderer data={upperData} user={user} />}
      </div>
    </div>
  );
};

export default Sidebar;
