import { FaCog, FaHeadphones, FaHome } from "react-icons/fa";
import { GiFarmTractor } from "react-icons/gi";
// import { AiOutlineQuestionCircle } from 'react-icons/ai';
// import { BiGitPullRequest } from 'react-icons/bi';
// import { BsNewspaper } from 'react-icons/bs';

export const upperData = [
  {
    id: 1,
    title: "ड्यासबोर्ड",
    title_en: "Dashboard",
    icon: <FaHome />,
    path: "/",
    rule: "view:dashboard",
  },
  // {
  // 	id: 2,
  // 	title: 'मूल्यसुची',
  // 	title_en: 'Price List',
  // 	icon: <FaRupeeSign />,
  // 	path: '/pricelist/1',
  // 	rule: 'view:priceList',
  // },
  {
    id: 3,
    title: "पटके प्रवेश आदेश ",
    title_en: "पटके प्रवेश आदेश",
    icon: <GiFarmTractor />,
    path: "/patake/1",
    rule: "view:customer",
  },
  {
    id: 4,
    title: "हाट बजार कर",
    title_en: "haatbazaar_vat",
    icon: <GiFarmTractor />,
    path: "/hatbazaar/1",
    rule: "view:support",
  },
  {
    id: 4,
    title: "भैसी पाडा",
    title_en: "pada_vaisi",
    icon: <GiFarmTractor />,
    path: "/vaisipaada/1",
    rule: "view:support",
  },
  {
    id: 5,
    title: "सडक मर्मत",
    title_en: "haatbazaar_vat",
    icon: <GiFarmTractor />,
    path: "/sadak/1",
    rule: "view:support",
  },
  {
    id: 6,
    title: "कबाड",
    title_en: "haatbazaar_vat",
    icon: <GiFarmTractor />,
    path: "/kabad/1",
    rule: "view:support",
  },

  // {
  // 	id: 4,
  // 	title: 'User',
  // 	title_en: 'User',
  // 	icon: <BiGitPullRequest />,
  // 	path: '/request/1',
  // 	rule: 'view:request',
  // },
  // {
  // 	id: 8,
  // 	title: 'समाचार/सूचना ',
  // 	title_en: 'News/Notice',
  // 	icon: <BsNewspaper />,
  // 	path: '/news/1',
  // 	rule: 'view:news',
  // },

  // {
  //   id: 7,
  //   title: "रिपोर्ट",
  //   title_en: "Report",
  //   icon: <FaChartBar />,
  //   path: "/reports",
  //   rule: "view:reports",
  // },
  {
    id: 8,
    title: "सेटिंग्स",
    title_en: "Settings",
    icon: <FaCog />,
    path: "/settings",
    rule: "view:settings",
  },
  // {
  // 	id: 9,
  // 	title: 'प्रायः सोधिने प्रश्नहरू',
  // 	title_en: 'FAQ',
  // 	icon: <AiOutlineQuestionCircle />,
  // 	path: '/faq',
  // 	rule: 'view:faq',
  // },
  {
    id: 10,
    title: "सपोर्ट",
    title_en: "Support",
    icon: <FaHeadphones />,
    path: "/support",
    rule: "view:support",
  },
];
