
export let monthMap = [
    'बैशाख',
    'जेठ',
    'असार',
    'श्रावन',
    'भाद्र',
    'असोज',
    'कार्तिक',
    'मंसिर',
    'पौष',
    'माघ',
    'फाल्गुन',
    'चैत्र',
];