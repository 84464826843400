import { Card, Row, Form, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { isNotEmpty } from "../../../_helpers/methods";
import FormGroup from "../../../components/FormGroup";
import {
  useDispatch,
  // useSelector,
} from "react-redux";
import {
  patchParticular,
  postParticular,
} from "../../../_redux/slices/unitCost";
// // import { useEffect, useState } from 'react';
// import {
// 	// getCostTypes,
// 	// postCostType,
// 	postUnitCost,
// } from '../../../_redux/slices/unitcost';

export default function UnitCostForm({ id, setShowUnitCostForm }) {
  const dispatch = useDispatch();
  // const { isLoading, particulars } = useSelector((state) => state.unitCost);

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  function onSubmit(data) {
    isNotEmpty(id)
      ? dispatch(patchParticular(data, id.id, setShowUnitCostForm))
      : dispatch(postParticular(data));
    reset();
    setShowUnitCostForm(false);
  }

  // function onCreate(value) {
  // 	dispatch(postCostType(value));
  // }

  return (
    <>
      <Card>
        <Card.Body>
          <h5 className="text-primary">
            {isNotEmpty(id) ? "शुल्क विवरण सम्पादन" : "नयाँ शुल्क विवरण"}
          </h5>
          <Row className="mt-4" as={Form} onSubmit={handleSubmit(onSubmit)}>
            <Col md={4}>
              <FormGroup.Text
                id="name"
                register={register}
                label="शिर्षक"
                defaultValue={isNotEmpty(id) ? id?.name : ""}
                errors={errors}
                required
              />
            </Col>
            <Col md={4}>
              <FormGroup.Select
                id="type"
                label="प्रकार"
                setValue={setValue}
                register={register}
                defaultValue={isNotEmpty(id) ? id?.type : null}
                options={[
                  { value: "bhaisi_paada", label: "पाडा भैसी " },
                  { value: "haatbazaar", label: "हाट बजार" },
                  { value: "sadak_marmat", label: "सडक मर्मत" },
                  { value: "kabaad", label: "कबाड " },
                  { value: "patake", label: "पटके" },
                ]}
                // isLoading={isLoading}
                errors={errors}
                // onCreate={onCreate}
                isSearchable
                required
              />
            </Col>
            <Col md={4}>
              <FormGroup.Text
                id="rate"
                as="rate"
                register={register}
                label="दर"
                defaultValue={isNotEmpty(id) ? id?.rate : ""}
                errors={errors}
                required
              />
            </Col>
            <Col md={4}>
              <FormGroup.Checkbox
                id="is_active"
                register={register}
                checkboxes={[
                  {
                    id: "is_active",
                    label: "सक्रिय?",
                    defaultChecked: isNotEmpty(id) ? id?.is_active : false,
                  },
                ]}
              />
            </Col>
            <Col md={12} className="d-flex justify-content-end">
              <Button variant="success" type="submit">
                पेश गर्नुहोस
              </Button>
              <Button
                variant="danger"
                className="ml-2"
                onClick={() => setShowUnitCostForm(false)}
              >
                रद्द गर्नुहोस
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
