import * as Yup from "yup";
import { isNotEmpty } from "./methods";

export const logValidationSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
});

export const forgetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Should be a valid email")
    .required("The Email is required"),
});
export const fiscalYearValidationSchema = Yup.object().shape({
  title: Yup.string()
    .matches(/[0-9]+\/[0-9]+/, {
      message: "आर्थिक वर्ष YYYY/YYY ढाँचामा हुनुपर्   छ  ",
    })
    .required("आर्थिक वर्ष आवश्यक    छ । "),
});

export const changePasswordValidation = Yup.object().shape({
  currentPassword: Yup.string()
    .required({
      en: "Password is required",
      np: "हालको पासवर्ड अनिवार्य    छ । ",
    })
    .min(8, {
      en: "Password must be at least 8 characters",
      np: "पासवर्ड 8 वर्णको हुनुपर्   छ ।",
    }),
  password: Yup.string()
    .required({
      en: "Password is required",
      np: "नयाँ पासवर्ड अनिवार्य    छ ।",
    })
    .min(8, {
      en: "Password must be at least 8 characters",
      np: "पासवर्ड 8 वर्णको हुनुपर्   छ ।",
    })
    .notOneOf([Yup.ref("currentPassword"), null], {
      en: "New Password cannot be same as Old Password",
      np: "नयाँ पासवर्ड, हाल पासवर्डभन्दा फरक हुनुपर्   छ ।",
    }),
  confirmPassword: Yup.string()
    .required({
      en: "Confirm Password is required",
      np: "पुन: नयाँ पासवर्ड अनिवार्य    छ ।",
    })
    .min(8, {
      np: "पासवर्ड 8 वर्णको हुनुपर्   छ ।",
      en: "Password must be at least 8 characters",
    })
    .oneOf([Yup.ref("password"), null], {
      en: "Confirm Password must match with New Password",
      np: "नयाँ पासवर्ड समान हुनुपर्   छ ।",
    }),
});

export const cropsValidation = Yup.object().shape({
  name: Yup.string().required("बालीको नाम आवश्यक    छ ।"),
  kind: Yup.string().required("बालीको जात आवश्यक    छ ।"),
  recomendedYear: Yup.string()
    .min(4, { message: "सिफारिस गरिएको वर्ष YYYY ढाँचामा हुनुपर्   छ ।" })
    .required("बालीको जात आवश्यक    छ ।"),
  harvestDays: Yup.string().required("पाक्ने दिन आवश्यक    छ ।"),
  plantationSpace: Yup.string().required("लगाउने दुरी आवश्यक    छ ।"),
  plantPerRopani: Yup.string().required("प्रति रोपनी बिरुवा आवश्यक    छ ।"),
  harvestCapacity: Yup.string().required("फसल क्षमता आवश्यक    छ ।"),
  harvestArea: Yup.string().required("फसल क्षेत्र आवश्यक    छ ।"),
  soilDepth: Yup.string().required("माटोको गहिराई आवश्यक    छ ।"),
  plantationMonthHilly: Yup.array(
    Yup.string().nullable()
    // .required('रोपण महिना आवश्यक  छ ।')
  )
    .length(2)
    .test({
      name: "required",
      test(value, ctx) {
        if (value.every((val) => typeof val === "string")) {
          return true;
        } else {
          return ctx.createError({ message: "रोपण महिना आवश्यक    छ ।" });
        }
      },
    }),
  plantationMonthTerai: Yup.array(
    Yup.string().nullable()
    // .required('रोपण महिना आवश्यक  छ ।')
  )
    .length(2)
    .test({
      name: "required",
      test(value, ctx) {
        if (value.every((val) => typeof val === "string")) {
          return true;
        } else {
          return ctx.createError({ message: "रोपण महिना आवश्यक    छ ।" });
        }
      },
    }),
});

export const requestValidation = Yup.object().shape({
  farmer: Yup.string().required("किसानको नाम आवश्यक    छ ।"),
  plantType: Yup.string().required("बिरुवाको प्रकार आवश्यक    छ ।"),
  plantationArea: Yup.string().required("क्षेत्रफल आवश्यक    छ ।"),
  plantName: Yup.string().required("बिरुवाको नाम आवश्यक    छ ।"),
  quantity: Yup.string().required("परिमाण आवश्यक    छ ।"),
  unit: Yup.string()
    .oneOf(
      ["pcs", "kilogram"],
      "इकाई निम्न मानहरू मध्ये एउटा हुनुपर्   छ ।: pcs, kg"
    )
    .required("इकाई आवश्यक    छ ।"),
  requiredDate: Yup.string().required("आवश्यक मिति आवश्यक    छ ।"),
});

export const customerValidation = Yup.object().shape({
  name: Yup.string().required("ग्राहकको नाम आवश्यक छ ।"),
  card_no: Yup.string().required("कार्ड नं आवश्यक छ ।"),
  area_code: Yup.string().required("एरिया कोड आवश्यक    छ ।"),
  address: Yup.string().required("ठेगाना आवश्यक छ ।"),
  phone: Yup.string().required("फोन आवश्यक छ ।"),
  last_payment_date: Yup.string(),
  due: Yup.string(),
});

export const faqValidation = Yup.object().shape({
  question: Yup.string().required("प्रश्न आवश्यक    छ ।"),
  answer: Yup.string().required("उत्तर आवश्यक    छ ।"),
});

export const newsValidation = Yup.object().shape({
  title: Yup.string().required("शीर्षक आवश्यक    छ ।"),
  type: Yup.string().required("प्रकार आवश्यक    छ ।"),
  description: Yup.string().required("विवरण आवश्यक    छ ।"),
  content: Yup.mixed().required("सामग्री आवश्यक    छ ।"),
});

// schema validation for the settings

//schema validation for disease

export const diseaseSchema = Yup.object().shape({
  name: Yup.string().required("रोगको नाम आवश्यक  छ ।"),
  crop: Yup.string().required("फसलको नाम आवश्यक  छ ।"),
  appearance: Yup.string().required("पहिचान आवश्यक  छ ।"),
  symptoms: Yup.string().required("लक्षणहरू आवश्यक  छ ।"),
  treatment: Yup.string().required("व्यवस्थापन विधी आवश्यक  छ ।"),
  prevention: Yup.string().required("रोकथाम आवश्यक  छ ।"),
});

// schema for the farm setting

export const farmSchema = Yup.object().shape({
  farmer: Yup.string().required("किसानको नाम आवश्यक  छ ।"),
  name: Yup.string().required("फार्मको नाम आवश्यक  छ ।"),
  area: Yup.string().required("क्षेत्रफल आवश्यक  छ ।"),
  typeOfLand: Yup.string().required("जग्गाको प्रकार आवश्यक  छ ।"),
  ph: Yup.string()
    .test({
      name: "ph",
      test(value, ctx) {
        if (+value <= 14) {
          return true;
        } else {
          return ctx.createError({
            message: "पी. एच. 14 भन्दा कम वा बराबर हुनुपर् छ ।",
          });
        }
      },
    })
    .required("पी. एच. आवश्यक   छ । ।"),
});

//schema  for the pocket Area

export const pocketAreaSchema = Yup.object().shape({
  crop: Yup.array(Yup.string().nullable().required("फसलको नाम आवश्यक  छ ।")),
  ward: Yup.string().required("वार्ड आवश्यक  छ ।"),
});

export const collectorSchema = (isEdit, type = "representative") => {
  return Yup.object().shape({
    fullname_np: Yup.string().required("संकलकको नाम आवश्यक  छ ।"),
    email: Yup.string().email("मान्य इमेल ठेगाना हुनुपर् छ ।"),
    phone: Yup.string()
      .max(10, { message: "फोन नम्बर बढीमा १० वर्णको हुनुपर् छ ।" })
      .required("फोन नम्बर आवश्यक  छ ।"),
    address: Yup.string("टोल आवश्यक  छ ।"),
    ...(!isEdit && type === "representative"
      ? {
          username: Yup.string().required("Username is required"),
          password: Yup.string()
            .test(
              "empty-or-8-characters-check",
              "पासवर्ड कम्तिमा ८ बर्णको हुनुपर्  छ । ।",
              (password) => !password || password.length >= 8
            )
            .required("Password is required"),
        }
      : {
          password: Yup.string().test(
            "empty-or-8-characters-check",
            "पासवर्ड कम्तिमा ८ बर्णको हुनुपर्  छ । ।",
            (password) => !password || password.length >= 8
          ),
        }),
  });
};

export const ThekdarSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required("ठेकदारको नाम आवश्यक  छ ।"),
    pan_no: Yup.string().required("प्यान आवश्यक  छ ।"),
    address: Yup.string().required("टोल आवश्यक  छ ।"),
    type: Yup.string().required("प्रकार आवश्यक  छ ।"),
  });
};
//schema validation for the palika users

export const palikaUserSchema = Yup.object().shape({
  name: Yup.string().required("प्रयोगकर्ताको नाम आवश्यक  छ ।"),
  email: Yup.string()
    .email("मान्य इमेल ठेगाना हुनुपर् छ ।")
    .required("इमेल आवश्यक  छ ।"),
  phone: Yup.string().required("फोन नम्बर आवश्यक  छ ।"),
  designation: Yup.string().required("पद आवश्यक  छ ।"),
  department: Yup.string().required("शाखा आवश्यक  छ ।"),
});

//schema for reset password
export const resetPassword = Yup.object().shape({
  newPassword: Yup.string().required("नयाँ पासवर्ड अनिवार्य  छ ।").min(8, {
    en: "Password must be at least 8 characters",
    np: "पासवर्ड 8 वर्णको हुनुपर् छ ।",
  }),
  confirmPassword: Yup.string()
    .required("पुन: नयाँ पासवर्ड अनिवार्य  छ ।")
    .min(8, "पासवर्ड 8 वर्णको हुनुपर् छ ।")
    .oneOf(
      [Yup.ref("newPassword"), null],

      "नयाँ पासवर्ड समान हुनुपर् छ ।"
    ),
});

//schema for pricelist

export const priceListSchema = (id) =>
  Yup.object().shape({
    crop: isNotEmpty(id)
      ? Yup.string()
      : Yup.string().required("बाली आवश्यक छ ।"),
    unit: isNotEmpty(id)
      ? Yup.string()
      : Yup.string().required("इकाई आवश्यक छ ।"),
    wholesaleSellingPrice: Yup.string().required("थोक मूल्य आवश्यक छ ।"),
    retailSellingPrice: Yup.string().required("खुद्रा  मूल्य आवश्यक छ ।"),
  });
