import { memo, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { debounce } from "../../_helpers/methods";
import FormGroup from "../../components/FormGroup";

const Filter = ({ filters = [], fetcher, page, limit }) => {
  const [queries, setQueries] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (fetcher) {
      dispatch(fetcher(`?page=1`));
    }
  }, [fetcher, dispatch]);
  const handleQuery = debounce((queries) => {
    let filterQuery = Object.entries(queries).reduce(
      (prev, curr, index) =>
        prev + `${index > 0 || page || limit ? "&" : ""}${curr[0]}=${curr[1]}`,
      `?${page ? `page=${page}` : ""}${limit ? `&limit=${limit}` : ""}`
    );
    fetcher && dispatch(fetcher(filterQuery));
  }, 2000);
  return (
    <div
      style={{
        display: "inline-flex",
        alignSelf: "flex-end",
        justifyContent: "flex-end",
        alignItems: "start",
        gap: "1rem",
      }}
    >
      {filters?.map((filter, i) => (
        <div key={filter?.key || i} style={{ display: "flex" }}>
          <p
            style={{
              marginBlockStart: "8px",
              marginInlineEnd: "4px",
              flexShrink: 1,
            }}
          >
            {filter?.label}
          </p>
          <FilterComponent
            filter={filter}
            setQueries={setQueries}
            handleQuery={handleQuery}
            queries={queries}
          />
        </div>
      ))}
      <Button
        variant="danger"
        onClick={() => {
          setQueries({});
          fetcher && dispatch(fetcher());
        }}
      >
        फिल्टर हटाउनुहोस
      </Button>
    </div>
  );
};

function FilterComponent({ filter, setQueries, handleQuery, queries }) {
  switch (filter?.type) {
    case "text":
      return (
        <FormGroup.Text
          id={filter?.key}
          placeholder={filter?.placeholder || `Search ${filter?.key}`}
          onChange={(e) => {
            setQueries((prev) => {
              return { ...prev, [filter?.key]: e.target.value };
            });
            handleQuery({
              ...queries,
              [filter?.key]: e.target.value,
            });
          }}
          value={queries[filter?.key] ? queries[filter?.key] : ""}
        />
      );
    case "select":
      return (
        <FormGroup.Select
          id={filter?.key}
          options={filter?.options}
          placeholder={filter?.placeholder || `Search ${filter?.key}`}
          isSearchable
          onChange={(value) => {
            setQueries((prev) => {
              return { ...prev, [filter?.key]: value?.value };
            });
            handleQuery({ ...queries, [filter?.key]: value?.value });
          }}
        />
      );
    case "date":
      return (
        <FormGroup.Date
          id={filter?.key}
          placeholder={filter?.placeholder || `Search ${filter?.key}`}
          onChange={(value) => {
            setQueries((prev) => {
              return { ...prev, [filter?.key]: value?.replace(/\//g, "-") };
            });
            handleQuery({
              ...queries,
              [filter?.key]: value.replace(/\//g, "-"),
            });
          }}
          value={queries[filter?.key] ? queries[filter?.key] : ""}
        />
      );
    default:
      return null;
  }
}

export default memo(Filter);
