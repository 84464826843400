import { jwtCheck } from './jwt-check';

export function authHeader() {
	// return authorization header with jwt token
	let Token = jwtCheck();
	let header = {
		'Content-Type': 'application/json',
	};
	if (Token) {
		header = { ...header, Authorization: `Bearer ${Token}` };
	}
	return header;
}
