import { Button, Card } from "react-bootstrap";
// import { Card, Row, Col, Button, Form } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

//
// import PaginationComp from "../../../components/Pagination";
import {
  getparticular,
  getparticulars,
  removeParticular,
} from "../../../_redux/slices/unitCost";
import CustomTable, { Action } from "../../../components/Table";

import FormGroup from "../../../components/FormGroup";
import PaginationComp from "../../../components/Pagination";
import UnitCostForm from "./UnitCostForm";

//
const UnitCost = () => {
  const { pageNum } = useParams();
  const [page, setPage] = useState(pageNum ? pageNum : 1);
  const [ParticularForm, setShowParticularForm] = useState(null);

  const dispatch = useDispatch();
  const { isLoading, particulars } = useSelector((state) => state.unitCost);
  useEffect(() => {
    dispatch(getparticulars());
  }, []);
  const types = {
    patake: "पटके",
    bhaisi_paada: "पाडा भैसी",
    haatbazaar: "हाट बजार",
    sadak_marmat: "सडक मर्मत",
    kabaad: "कबाड",
  };

  const handleSubmit = (V) => {
    const type = V.value;
    type === "all" ? dispatch(getparticulars()) : dispatch(getparticular(type));
  };
  const headers = [
    [
      {
        key: "type",
        title: "प्रकार",
        render: (thekdar) => {
          return types[thekdar?.type];
        },
      },
      {
        key: "name",
        title: "शिर्षक",
      },

      {
        key: "rate",
        title: "दर ",
      },
      {
        key: "is_active",
        title: "सक्रिय ",
        render: (data) => {
          return (
            <div>
              {data.is_active ? (
                <p className="text-success">Active</p>
              ) : (
                <p>Not Active</p>
              )}
            </div>
          );
        },
      },

      {
        key: "actions",
        title: "कार्यहरु",
        render: (data) => {
          return (
            <div
              className="d-flex align-items-center"
              style={{ columnGap: "0.5rem" }}
            >
              <Action.Edit onClick={() => setShowParticularForm(data)} />
              <Action.Delete
                className="cursor-pointer"
                onClick={() => {
                  dispatch(removeParticular(data?.id));
                }}
              />
            </div>
          );
        },
      },
    ],
  ];
  console.log("data", ParticularForm);
  return (
    <Fragment>
      {ParticularForm && (
        <UnitCostForm
          id={ParticularForm}
          setShowUnitCostForm={setShowParticularForm}
        />
      )}
      <Card>
        <Card.Body>
          <div className="d-flex  justify-content-between align-items-center">
            <h4 className="text-primary">शुल्क विवरण </h4>
            <div className="d-flex align-items-center">
              <h4 className="text-primary mr-3">प्रकार</h4>
              <FormGroup.Select
                options={[
                  { value: "all", label: "सबै" },
                  { value: "bhaisi_paada", label: "पाडा भैसी " },
                  { value: "haatbazaar", label: "हाट बजार" },
                  { value: "sadak_marmat", label: "सडक मर्मत" },
                  { value: "kabaad", label: "कबाड " },
                  { value: "patake", label: "पटके" },
                ]}
                onChange={(v) => {
                  handleSubmit(v);
                }}
              />
            </div>
            <div>
              <Button onClick={() => setShowParticularForm(true)}>
                नयाँ शुल्क विवरण बनाउनुहोस
              </Button>
            </div>
          </div>
          <div className="pt-4">
            <CustomTable
              headers={headers}
              data={particulars}
              isLoading={isLoading}
            />
            <PaginationComp page={page} setPage={setPage} />
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default UnitCost;
