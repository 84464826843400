import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { setUiError, setUiMessage } from './ui';
import { errorMessageHandler } from '../../_helpers/methods';

const initialState = {
	isLoading: false,
	customers: [],
	customer: null,
	customerPaymentHistory: [],
	customerPaymentHistoryCount: 0,
	count: 0,
};

const customerSlice = createSlice({
	name: 'customer',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = action.payload;
		},
		setcustomers: (state, action) => {
			state.customers = action.payload.results;
			state.count = action.payload.count;
		},
		setcustomer: (state, action) => {
			state.customer = action.payload;
		},
		setcustomerpaymenthistory: (state, action) => {
			state.customerPaymentHistory = action.payload.results;
			state.customerPaymentHistoryCount = action.payload.count;
		},
		addcustomer: (state, action) => {
			state.customers = [action.payload, ...state.customers];
			state.count++;
		},
		removecustomer: (state, action) => {
			state.customers = state.customers.filter(
				(item) => item.id !== action.payload
			);
			state.count--;
		},
		editcustomer: (state, action) => {
			state.customers = state.customers.map((item) => {
				if (item.id === action.payload.id) {
					return action.payload;
				} else {
					return item;
				}
			});
		},
	},
});

export default customerSlice.reducer;

const {
	setLoading,
	setcustomer,
	setcustomerpaymenthistory,
	setcustomers,
	addcustomer,
	removecustomer,
	editcustomer,
} = customerSlice.actions;

// Actions

export const getcustomers =
	(queries = '') =>
		async (dispatch) => {
			try {
				dispatch(setLoading(true));
				const response = await fetchWrapper.get(`/profile/customer/${queries}`);
				dispatch(setcustomers(response?.data));
				dispatch(setLoading(false));
			} catch (error) {
				dispatch(setLoading(false));
				dispatch(setUiError(error?.message));
			}
		};

export const getcustomer = (id) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const response = await fetchWrapper.get(`/profile/customer/${id}/`);
		dispatch(setcustomer(response?.data));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
		dispatch(setUiError(error?.message));
	}
};

export const getcustomerpaymenthistory = (id, page) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const response = await fetchWrapper.get(`/payment/?customer=${id}&limit=10&offset=${(page - 1) * 10}`);
		dispatch(setcustomerpaymenthistory(response?.data));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
		dispatch(setUiError(error?.message));
	}
};



export const postcustomer = (data, callback) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const response = await fetchWrapper.post('/profile/customer/', data);
		dispatch(addcustomer(response?.data));
		dispatch(setUiMessage(response?.message));
		dispatch(setLoading(false));
		callback?.();
	} catch (error) {
		dispatch(setLoading(false));
		dispatch(setUiError(errorMessageHandler(error)));
	}
};

export const patchcustomer = (id, data, callback) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const response = await fetchWrapper.patch(`/profile/customer/${id}/`, data);
		dispatch(editcustomer(response?.data));
		dispatch(setUiMessage(response?.message));
		dispatch(setLoading(false));
		callback?.();
	} catch (error) {
		dispatch(setLoading(false));
		dispatch(setUiError(errorMessageHandler(error)));
	}
};

export const deletecustomer = (id) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const response = await fetchWrapper.delete(`/profile/customer/${id}/`);
		dispatch(removecustomer(id));
		dispatch(setUiMessage(response?.message));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
		dispatch(setUiError(error?.message));
	}
};
