const admin_rules = [
  // sidebar
  "view:dashboard",
  "view:priceList",
  "view:customer",
  "view:request",
  "view:support",
  "view:news",
  "view:faq",
  "view:settings",
  "view:reports",
  // settings
  "view:arthikBarsa",
  "view:nagarpalika",
  "view:unitcost",
  "view:users",
  "view:wodas",
  "view:nakas",
  "view:thekedar"
];
const collector_rules = [
  // sidebar
  "view:dashboard",
  "view:priceList",
  "view:customer",
  "view:request",
  "view:support",
  "view:news",
  "view:faq",
  "view:reports",
  "view:nakas",
];

const rules = {
  collector: {
    static: collector_rules,
  },
  superuser: {
    static: admin_rules,
  },
};

export default rules;
