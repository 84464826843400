import React, { useState } from "react";
import { Button } from "react-bootstrap";
import {
  AiOutlineCloudDownload,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { fetchWrapper } from "../../_helpers/fetchWrapper";

const Export = ({ url }) => {
  const [isLoading, setIsLoading] = useState(false);
  async function onExport() {
    try {
      setIsLoading(true);
      const response = await fetchWrapper.get(url + "/export/?type=excel");
      const downloadLink = document.createElement("a");
      downloadLink.setAttribute("href", response?.data?.url);
      downloadLink.setAttribute("target", "_blank");
      downloadLink.setAttribute("download", "export");
    } catch (error) {
      setIsLoading(false);
    }
    setIsLoading(false);
  }
  return (
    <Button
      variant={isLoading ? "secondary" : "outline-primary"}
      className="d-flex align-items-center"
      onClick={onExport}
      disabled={isLoading}
    >
      <span className="mr-2">Export to Excel</span>
      {isLoading ? (
        <AiOutlineLoading3Quarters className="spin" />
      ) : (
        <AiOutlineCloudDownload />
      )}
    </Button>
  );
};

export default Export;
