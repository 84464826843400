import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import PaginationComp from "../../components/Pagination";
import CustomTable, { Action } from "../../components/Table";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// import { ADToBS } from "../../components/FormGroup/DatePicker/AdToBsConverter/AdBsConverter";
// import moment from "moment";
import moment from "moment";
import { englishToNepaliNumber } from "nepali-number";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components";
import { getVaisipada } from "../../_redux/slices/vaisipada";
import Export from "../../components/Buttons/Export";
import { ADToBS } from "../../components/FormGroup/DatePicker/AdToBsConverter/AdBsConverter";
import ShowParticular from "../Hatbazaar/ShowParticular";

const VaisiPaada = () => {
  const history = useHistory();
  const { pageNum } = useParams();
  const [page, setPage] = useState(pageNum);
  const dispatch = useDispatch();
  const { isLoading, results, count, totalAmount } = useSelector(
    (state) => state.vaisipada
  );
  const printRef = useRef(null);
  const [showHatbazaarForm, setshowHatbazaarForm] = React.useState(null);
  const [print, setPrint] = useState(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  useEffect(() => {
    dispatch(
      getVaisipada(
        `?from=${ADToBS(moment().format("YYYY-MM-DD")).replace(
          /\//g,
          "-"
        )}&to=${ADToBS(moment().format("YYYY-MM-DD")).replace(/\//g, "-")}`
      )
    );
    // history.push(`/vaisipaada/${page}`);
  }, [dispatch, history, page]);

  function handleOpenPrint(data) {
    return () => {
      setPrint(data);
    };
  }

  function handleClosePrint() {
    setPrint(null);
    printRef.current = null;
  }
  function handleCloseParticular() {
    setshowHatbazaarForm(false);
  }

  const headers = [
    [
      {
        key: "date",
        title: "मिति",
        rowSpan: 2,
        order: 1,
        render: (data) => (
          <>
            <p>{ADToBS(moment(data?.data).format("YYYY-MM-DD"))}</p>
            {/* <p>{patake?.permission_time_bs}</p> */}
          </>
        ),
      },
      {
        key: "bill_no",
        title: "बिल नं :",
      },
      {
        key: "name",
        title: "नाम",
      },
      {
        key: "address",
        title: "ठेगाना ",
      },
      {
        key: "total",
        title: "कुल रकम ",
      },

      {
        key: "actions",
        title: "कार्यहरु",
        rowSpan: 2,
        order: 11,
        render: (data) => {
          return (
            <div
              className="d-flex align-items-center"
              style={{ columnGap: "0.5rem" }}
            >
              <Action.View onClick={() => setshowHatbazaarForm(data)} />

              <Action.Print onClick={handleOpenPrint(results)} />
            </div>
          );
        },
      },
    ],
  ];

  const filters = [
    {
      key: "from",
      type: "date",
      label: "Date from:",
    },
    {
      key: "to",
      type: "date",
      label: "Date to:",
    },
  ];
  return (
    <Fragment>
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="text-primary"> भैसी पाडा शुल्क विवरण </h3>
            <Export url={"/bhaisipada"} />
          </div>
          <div>
            <CustomTable
              headers={headers}
              data={results}
              isLoading={isLoading}
              filter={{
                filters,
                fetcher: getVaisipada,
                page,
              }}
              leftContent={
                <div>
                  <span style={{ fontWeight: "bold" }}>कुल रकम: </span>
                  <span>
                    {isLoading
                      ? "loading..."
                      : englishToNepaliNumber(totalAmount?.toString() || "0")}
                  </span>
                </div>
              }
            />
            <PaginationComp total={count} page={page} setPage={setPage} />
          </div>
        </Card.Body>
      </Card>
      {showHatbazaarForm && (
        <StyledModal
          show={Boolean(showHatbazaarForm)}
          onHide={handleCloseParticular}
        >
          <Modal.Header closeButton>विवरण</Modal.Header>
          <Modal.Body>
            <ShowParticular
              ShowParticular={showHatbazaarForm}
              particularName={"पाडा भैसी  कर "}
            />
          </Modal.Body>
        </StyledModal>
      )}
    </Fragment>
  );
};

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 210mm;
  }
`;

export default VaisiPaada;
