import { Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, Fragment, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

//
import NakaForm from "./NakaForm";
//
import PaginationComp from "../../../components/Pagination";
import CustomTable, { Action } from "../../../components/Table";

//
import { fetchAllNakas, removeNaka } from "../../../_redux/slices/naka";

//
const Nakas = () => {
  const { pageNum, kholaId } = useParams();
  const dispatch = useDispatch();
  const [showWardForm, setShowWardForm] = useState(null);
  const [page, setPage] = useState(pageNum ? pageNum : 1);
  const { loading, nakas, count } = useSelector((state) => state.naka);

  useEffect(() => {
    dispatch(fetchAllNakas(page));
  }, [dispatch, page, kholaId]);

  const headers = [
    [
      {
        key: "name_np",
        title: "नाम",
      },
      {
        key: "address_np",
        title: "ठेगाना",
      },
      {
        key: "phone_en",
        title: "फोन",
      },
      {
        key: "actions",
        title: "कार्यहरु",
        render: (ward) => {
          return (
            <div
              className="d-flex align-items-center"
              style={{ columnGap: "0.5rem" }}
            >
              <Action.Edit onClick={() => setShowWardForm(ward)} />
              <Action.Delete
                className="cursor-pointer"
                onClick={() => {
                  dispatch(removeNaka(ward.id));
                }}
              />
            </div>
          );
        },
      },
    ],
  ];
  return (
    <Fragment>
      {showWardForm && (
        <NakaForm id={showWardForm} setShowWardForm={setShowWardForm} />
      )}
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="text-primary">नाका</h4>
            <div>
              <Button onClick={() => setShowWardForm(true)}>
                नयाँ नाका बनाउनुहोस
              </Button>
            </div>
          </div>
          <div className="pt-4">
            <CustomTable headers={headers} data={nakas} isLoading={loading} />
            <PaginationComp total={count} page={page} setPage={setPage} />
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default Nakas;
